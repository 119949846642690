import Decimal from 'decimal.js-light';

import { TariffTypeEnum } from '@/apolloGenerated';

export const getPaidOrdersCount = (
  count: number,
  tariffType: TariffTypeEnum,
) => {
  if (tariffType === TariffTypeEnum.Pair) {
    return Math.ceil(new Decimal(count).div(2).toNumber());
  }

  return count;
};
