import React, { FC, useEffect } from 'react';

import { useRemoveEventMutation } from '@/apolloGenerated';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  Typography,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface RemoveEventModalProps {
  uuid: string;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    removeEvent: RemoveEventModalProps;
  }
}
export const RemoveEventModal: FC<WithCloseModal<RemoveEventModalProps>> = ({
  onClose,
  onSuccess,
  uuid,
}): JSX.Element => {
  const [removeEvent, { client, data, error, loading }] =
    useRemoveEventMutation();

  const handleSubmit = () => {
    removeEvent({ variables: { uuid } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Вечеринка успешно удалена',
      });
      client.cache.evict({ fieldName: 'productsList' });
      client.cache.evict({ fieldName: 'productsCount' });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка удаления. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Удалить черновик?"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button
                color="danger"
                fullWidth
                onClick={handleSubmit}
                disabled={loading}>
                Удалить
              </Button>
            </div>
          }
        />
      }>
      <Typography variant="body-16" color="on-surface-primary-1">
        Восстановить удаленный черновик будет невозможно.
      </Typography>
    </Dialog>
  );
};
