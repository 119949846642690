import { useEffect } from 'react';

import {
  useBlockUserMutation,
  useUnblockUserMutation,
} from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { toaster } from '@letsdance/ui-kit';

export const useBlacklistAction = () => {
  const organizerId = useOrganizerId()!;
  const [blockUser, { client, data: addData, error: addError }] =
    useBlockUserMutation();
  const [unblockUser, { data: removeData, error: removeError }] =
    useUnblockUserMutation();

  const updateCache = (userUuid: string) => {
    client.cache.evict({ fieldName: 'users' });
    client.cache.evict({ fieldName: 'blacklist' });
    client.cache.evict({ args: { uuid: userUuid }, fieldName: 'user' });
  };

  const handleAddUser = (userUuid: string) =>
    blockUser({ variables: { organizerId, userUuid } }).then(() =>
      updateCache(userUuid),
    );
  const handleRemoveUser = (userUuid: string) =>
    unblockUser({ variables: { organizerId, userUuid } }).then(() =>
      updateCache(userUuid),
    );

  useEffect(() => {
    if (addData) {
      toaster.success({ title: 'Пользователь заблокирован' });
    }
    if (addError) {
      toaster.error({ title: 'Ошибка блокировки пользователя' });
    }
  }, [addData, addError]);

  useEffect(() => {
    if (removeData) {
      toaster.success({ title: 'Пользователь разблокирован' });
    }
    if (removeError) {
      toaster.error({ title: 'Ошибка разблокировки пользователя' });
    }
  }, [removeData, removeError]);

  return { onBlockUser: handleAddUser, onUnblockUser: handleRemoveUser };
};
