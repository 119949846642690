import React, { FC, useState } from 'react';

import { Container } from '@/shared';
import {
  ContactListTabs,
  ContactTabs,
  ContactTabsEnum,
} from '@entities/Contact';
import {
  ContactListFilter,
  ContactListFilterData,
} from '@entities/Contact/ui/ContactListFilter/ContactListFilter';
import { ContactTable } from '@features/Contact';
import { Divider, Spacer } from '@letsdance/ui-kit';

export const ContactLists: FC = () => {
  const [tab, setTab] = useState<ContactTabs>(ContactTabsEnum.All);
  const [filter, setFilter] = useState<ContactListFilterData>({ search: '' });

  return (
    <>
      <Container>
        <ContactListTabs onChange={setTab} />
      </Container>
      <Divider />
      <Spacer size={12} />
      <Container>
        <ContactListFilter initValue={filter} onChange={setFilter} />
        <Spacer size={4} />
        <ContactTable type={tab} searchValue={filter.search} />
      </Container>
    </>
  );
};
