import { FC } from 'react';

import { DesignIcon, IconProps, LockIcon, StarIcon } from '@letsdance/ui-kit';

export interface ContactStatusIconProps {
  isSpecial?: boolean;
  isBlocked?: boolean;
}
export const ContactStatusIcon: FC<ContactStatusIconProps & IconProps> = ({
  color = 'var(--on-surface-secondary-1)',
  isBlocked,
  isSpecial,
  ...iconProps
}) => {
  if (isBlocked) {
    return <LockIcon color={color} {...iconProps} />;
  }
  if (isSpecial) {
    return <StarIcon color={color} {...iconProps} />;
  }

  return <DesignIcon color="transparent" />;
};
