import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import {
  FacebookIcon,
  InstagramIcon,
  TelegramIcon,
  Typography,
  VkIcon,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export const Footer: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__bottom}>
        <div className={styles.footer__social}>
          <TelegramIcon width={50} height={50} />
          <VkIcon width={50} height={50} />
          <InstagramIcon width={50} height={50} />
          <FacebookIcon width={50} height={50} />
        </div>
        <div className={styles.footer__text}>
          <Typography
            variant={isDesktop ? 'body-16' : 'body-14'}
            tag={'div'}
            className={styles.footer__menu}>
            <a href="/docs/policy.pdf" target="_blank">
              Политика конфиденциальности
            </a>
            <a href="/docs/oferta.pdf" target="_blank">
              Публичная оферта
            </a>
          </Typography>
          <Typography variant={isDesktop ? 'body-16' : 'body-14'}>
            © 2024 W2Dance. Все права защищены
          </Typography>
          {isDesktop && <Typography variant={'head-32'}>W2Dance</Typography>}
        </div>
        {!isDesktop && <Typography variant={'head-24'}>W2Dance</Typography>}
      </div>
    </footer>
  );
};
