import { gql } from '@apollo/client';

export const eventsCount = gql`
  query eventsCount($idOrganizer: Float) {
    productsCount(idOrganizer: $idOrganizer) {
      count
      activeCount
      archiveCount
      draftCount
    }
  }
`;

export const eventsList = gql`
  query eventsList(
    $filters: ProductListFilterInput
    $params: ProductFilter
    $status: [StatusEnum!]
  ) {
    productsList(filters: $filters, params: $params, status: $status) {
      rows {
        createdAt
        updatedAt
        uuid
        status
        purchasedCount
        visitedCount
        receipts
        name
        query
        startedDate
        endDate
      }
      count
    }
  }
`;

export const event = gql`
  query event($uuid: UUID!) {
    product(uuid: $uuid) {
      createdAt
      updatedAt
      uuid
      img
      name
      description
      address
      query
      startedDate
      endDate
      status
      preview {
        active
        url
        description
        backButtonText
      }
      route {
        active
        url
        description
        backButtonText
      }
      featureFlags {
        refundOrder
        transferToUserOrder
        transferFromProductOrder
        transferToProductOrder
        repeatBuyOrder
        durationBeforeStartForRefundOrder
        durationBeforeStartForTransferToUserOrder
        allowedNumberForTransferToProductOrder
      }
      successBuyText
      tariffsText
      tariffsEmptyText
      manualBuyText
      activeAcquiring
      faq
      invoiceLifetime
      salesStartedDate
      transferTariff {
        uuid
      }
    }
  }
`;

export const archiveEvent = gql`
  mutation archiveEvent($uuid: UUID!) {
    archiveProduct(uuid: $uuid)
  }
`;

export const publishEvent = gql`
  mutation publishEvent($uuid: UUID!) {
    activateProduct(uuid: $uuid)
  }
`;

export const removeEvent = gql`
  mutation removeEvent($uuid: UUID!) {
    removeProduct(uuid: $uuid)
  }
`;

export const updateEvent = gql`
  mutation updateEvent($uuid: UUID!, $input: ProductInput!) {
    updateProduct(input: $input, uuid: $uuid) {
      uuid
    }
  }
`;

export const createEvent = gql`
  mutation createEvent(
    $publish: Boolean
    $input: ProductInput!
    $organizerId: Float!
  ) {
    createProduct(publish: $publish, input: $input, organizerId: $organizerId) {
      uuid
    }
  }
`;
