import { TariffInput, TariffTypeEnum } from '@/apolloGenerated';

export const getTariffInput = ({
  description,
  endDate,
  featureFlags: {
    refundOrder,
    repeatBuyOrder,
    transferToProductOrder,
    transferToUserOrder,
  },
  name,
  ordersCount,
  price,
  startedDate,
  type,
  visible,
}: TariffInput) => ({
  description,
  endDate,
  featureFlags: {
    refundOrder,
    repeatBuyOrder,
    transferToProductOrder,
    transferToUserOrder,
  },
  name,
  ordersCount,
  price,
  startedDate,
  type,
  visible,
});

export const getTariffInitState = (): TariffInput => ({
  description: null,
  endDate: null,
  featureFlags: {
    refundOrder: true,
    repeatBuyOrder: true,
    transferToProductOrder: true,
    transferToUserOrder: true,
  },
  name: '',
  ordersCount: null,
  price: 0,
  startedDate: null,
  type: TariffTypeEnum.AnyGender,
  visible: true,
});
