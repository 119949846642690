import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { openSupportTg } from '@/shared';
import { Button, Typography } from '@letsdance/ui-kit';
import offersImg from '@shared/images/offers.png';
import offersImgMobile from '@shared/images/offers-mobile.png';
import { OFFERS } from '@widgets/Landing/const/text.conts';
import { CheckCircle } from '@widgets/Landing/ui/icons';

import cls from './style.module.scss';

export const Offers: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  const openH2Dance = () => {
    window.open('https://h2.dance/', '_blank', 'noreferrer, noopener');
  };

  return (
    <section className={cls.section}>
      {!isDesktop && (
        <Typography variant={'head-32'}>Решения для каждого</Typography>
      )}
      <img src={isDesktop ? offersImg : offersImgMobile} alt="offers" />
      <div className={cls.list}>
        {isDesktop && (
          <Typography variant={'head-48'}>Решения для каждого</Typography>
        )}
        {OFFERS &&
          OFFERS.map(([title, desc, isH2dance]) => (
            <div key={String(title)} className={cls.item}>
              <div>
                <CheckCircle width={30} height={30} />
              </div>
              <div className={cls.desc}>
                <Typography variant={'head-20'}>{title}</Typography>
                <Typography
                  tag={'div'}
                  variant={isDesktop ? 'body-20' : 'body-16'}
                  className={cls.text}>
                  {desc}
                </Typography>
                <Button
                  variant={'outlined'}
                  onClick={isH2dance ? openH2Dance : openSupportTg}>
                  Узнать больше
                </Button>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};
