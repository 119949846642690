import Decimal from 'decimal.js-light';

import { Maybe, SortEnum, StatusEnum } from '@/apolloGenerated';
import { SortType } from '@letsdance/ui-kit';

export const formatAmount = (number: number, fixed?: boolean): string =>
  Intl.NumberFormat('ru-Ru', {
    currency: 'RUB',
    ...(fixed
      ? { maximumFractionDigits: 0 }
      : { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
  }).format(number);
export const formatSort = (sort: Maybe<SortType>): SortEnum | undefined => {
  if (!sort) {
    return undefined;
  }

  return sort === 'asc' ? SortEnum.Asc : SortEnum.Desc;
};

export const readableStatus = (status: StatusEnum) => {
  switch (status) {
    case StatusEnum.Active: {
      return 'Активный';
    }
    case StatusEnum.Archive: {
      return 'Архивный';
    }
    case StatusEnum.Draft: {
      return 'Черновик';
    }
  }
};

export const calculateProfit = (price: number, holdPercentage: number) => {
  const commissionAmount = new Decimal(holdPercentage / 100)
    .mul(new Decimal(price))
    .toNumber();

  return {
    commission: commissionAmount,
    profit: new Decimal(price).minus(commissionAmount).toNumber(),
    turnover: price,
  };
};

export const declension = function (
  number: number,
  words: [string, string, string],
): string {
  const cases = [2, 0, 1, 1, 1, 2];

  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};
