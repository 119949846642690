export enum Role {
  USER = 'user',
  GHOST = 'ghost',
}
export enum RouterName {
  Home = 'home',
  Wiki = 'wiki',
  NotFound = '404',
  Authorization = 'auth',
  AdminEvents = 'admin-events',
  AdminProfile = 'admin-profile',
  AdminContact = 'contact',
  AdminPurchases = 'admin-purchases',
  AdminFinance = 'admin-finances',
  AdminWithdrawCreate = 'admin-withdraw-create',
  AdminContactEdit = 'admin-contact-edit',
  AdminEventEdit = 'admin-event-edit',
  AdminEventCreate = 'admin-event-create',
}
