import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Typography } from '@letsdance/ui-kit';
import phoneBgImg from '@shared/images/phone-bg.png';

import 'swiper/css/pagination';

import { AUTOMATION } from '../../const/text.conts';

import 'swiper/css';
import cls from './style.module.scss';

export const Automation: FC = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });

  return (
    <section className={cls.automation}>
      <Typography
        variant={isDesktop ? 'head-60' : 'head-32'}
        className={cls.automation__title}>
        Автоматизируйте
        <br /> рутинные операции
      </Typography>
      {isDesktop ? (
        <div className={cls.automation__container}>
          {AUTOMATION &&
            AUTOMATION.map(([title, desc], index) => (
              <div
                className={cx(
                  cls.automation__item,
                  cls[`automation__item_${index}`],
                )}
                key={String(title)}>
                <Typography variant={'head-24'}>{title}</Typography>
                <Typography
                  tag="div"
                  variant={isDesktop ? 'body-20' : 'body-16'}
                  className={cls.automation__desc}>
                  {desc}
                  {index === 0 && isDesktop && (
                    <img
                      src={phoneBgImg}
                      alt={'phone'}
                      className={cls.automation__image}
                    />
                  )}
                </Typography>
              </div>
            ))}
        </div>
      ) : (
        <Swiper
          slidesPerView={1}
          navigation
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className={cls.swiper}>
          {AUTOMATION &&
            AUTOMATION.map(([title, desc], index) => (
              <SwiperSlide key={String(title)}>
                <div
                  key={String(title)}
                  className={cx(
                    cls.automation__item,
                    cls[`automation__item_${index}`],
                  )}>
                  <Typography variant={'head-24'}>{title}</Typography>
                  <Typography
                    tag="div"
                    variant={isDesktop ? 'body-20' : 'body-16'}
                    className={cls.automation__desc}>
                    {desc}
                    {index === 0 && isDesktop && (
                      <img
                        src={phoneBgImg}
                        alt={'phone'}
                        className={cls.automation__image}
                      />
                    )}
                  </Typography>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </section>
  );
};
