import React, { FC, useEffect } from 'react';

import {
  OrganizerInput,
  OrganizerSocialInput,
  OrganizerSupplierInfoInput,
  OrganizerType,
} from '@/apolloGenerated';
import {
  DeeplinkCopy,
  FormSection,
  handleNumberInput,
  useImageUploader,
} from '@/shared';
import {
  ImageUploader,
  NestedKeys,
  Spacer,
  TextField,
  useForm,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface EventFormProps {
  initValues: OrganizerType;
  onChange(val: OrganizerInput): void;
  errors?: Record<NestedKeys<OrganizerInput>, string[]>;
}
export const OrganizerForm: FC<EventFormProps> = ({
  errors,
  initValues,
  onChange,
}) => {
  const uploadImage = useImageUploader();
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<OrganizerInput>(console.log, initValues);

  useEffect(() => {
    onChange(values);
  }, [values]);

  const updateSocial = function <T extends keyof OrganizerSocialInput>(
    key: T,
    value: OrganizerSocialInput[T],
  ) {
    setBatchValues({
      socials: {
        ...values.socials,
        [key]: value,
      },
    });
  };
  const updateSupplierInfo = function <
    T extends keyof OrganizerSupplierInfoInput,
  >(key: T, value: OrganizerSupplierInfoInput[T]) {
    setBatchValues({
      supplierInfo: {
        ...values.supplierInfo,
        [key]: value || null,
      },
    });
  };

  return (
    <div className={styles.organizerForm}>
      <FormSection title="Основная информация">
        <div className={styles.organizerForm__mainInfo}>
          <ImageUploader
            initValue={values.image}
            onChange={(val) => setBatchValues({ image: val })}
            error={errors?.image}
            uploadImageCallback={uploadImage}
          />
          <div>
            <TextField
              label="Название"
              size="large"
              fullWidth
              error={errors?.name}
              name="name"
              initValue={values.name}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <TextField
              label="Telegram"
              size="large"
              fullWidth
              error={errors?.telegram}
              name="telegram"
              initValue={values.telegram!}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <TextField
              label="Ключ диплинка (латиница)"
              size="large"
              fullWidth
              error={errors?.query}
              name="query"
              initValue={values.query!}
              onChange={onChangeValue}
            />
            <Spacer size={10} />
            <DeeplinkCopy to="organizer" value={values.query} />
          </div>
        </div>
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Описание"
          size="large"
          style={{ height: 200 }}
          fullWidth
          error={errors?.description}
          name="description"
          initValue={values.description}
          onChange={onChangeValue}
        />
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Условия использования"
          size="large"
          style={{ height: 200 }}
          fullWidth
          error={errors?.termsOfUse}
          name="termsOfUse"
          initValue={values.termsOfUse || ''}
          onChange={onChangeValue}
        />
      </FormSection>
      <FormSection title="Социальные сети">
        <div className={styles.organizerForm__mainInfo}>
          <ImageUploader
            initValue={values.socials?.image}
            error={errors?.['socials.image']}
            onChange={(val) => updateSocial('image', val)}
            uploadImageCallback={uploadImage}
          />
          <div>
            <div className="grid-2">
              <TextField
                label="Telegram"
                size="large"
                fullWidth
                error={errors?.['socials.telegram']}
                initValue={values.socials?.telegram || ''}
                onChange={(e) => updateSocial('telegram', e.target.value)}
              />
              <TextField
                label="Vk"
                size="large"
                fullWidth
                error={errors?.['socials.vk']}
                initValue={values.socials?.vk || ''}
                onChange={(e) => updateSocial('vk', e.target.value)}
              />
            </div>
            <Spacer size={10} />
            <div className="grid-2">
              <TextField
                label="Facebook"
                size="large"
                fullWidth
                error={errors?.['socials.facebook']}
                initValue={values.socials?.facebook || ''}
                onChange={(e) => updateSocial('facebook', e.target.value)}
              />
              <TextField
                label="Instagram"
                size="large"
                fullWidth
                error={errors?.['socials.instagram']}
                initValue={values.socials?.instagram || ''}
                onChange={(e) => updateSocial('instagram', e.target.value)}
              />
            </div>
            <Spacer size={10} />
            <div className="grid-2">
              <TextField
                label="YouTube"
                size="large"
                fullWidth
                error={errors?.['socials.youtube']}
                initValue={values.socials?.youtube || ''}
                onChange={(e) => updateSocial('youtube', e.target.value)}
              />
            </div>
          </div>
        </div>
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Текст на экране соц. сетей"
          size="large"
          style={{ height: 200 }}
          fullWidth
          error={errors?.termsOfUse}
          initValue={values.socials?.text || ''}
          onChange={(e) => updateSocial('text', e.target.value)}
        />
      </FormSection>
      <FormSection title="Платёжная информация">
        <div className="grid-3">
          <TextField
            label="ФИО"
            size="large"
            fullWidth
            error={errors?.['supplierInfo.name']}
            initValue={values.supplierInfo?.name || ''}
            onChange={(e) => updateSupplierInfo('name', e.target.value)}
          />
          <TextField
            label="Номер телефона"
            size="large"
            fullWidth
            error={errors?.['supplierInfo.phone']}
            initValue={values.supplierInfo?.phone || ''}
            onChange={(e) => updateSupplierInfo('phone', e.target.value)}
          />
          <TextField
            label="ИНН"
            size="large"
            fullWidth
            error={errors?.['supplierInfo.inn']}
            onKeyPress={handleNumberInput}
            initValue={values.supplierInfo?.inn || ''}
            onChange={(e) => updateSupplierInfo('inn', e.target.value)}
          />
        </div>
      </FormSection>
    </div>
  );
};
