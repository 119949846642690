import { FC, useEffect, useMemo, useState } from 'react';

import {
  StatusEnum,
  TariffTypeEnum,
  useTariffsListQuery,
} from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { Chip, Maybe } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface EventPickerProps {
  initValue?: Maybe<string>;
  onChange(value: Maybe<string>): void;
  status?: StatusEnum[];
  productUuid: string;
  hideAll?: boolean;
  autoSetFirst?: boolean;
  hideTariffType?: TariffTypeEnum[];
}
export const TariffPicker: FC<EventPickerProps> = ({
  autoSetFirst,
  hideAll,
  hideTariffType,
  initValue,
  onChange,
  productUuid,
  status = [StatusEnum.Active, StatusEnum.Archive],
}) => {
  const organizerId = useOrganizerId()!;
  const { data } = useTariffsListQuery({
    skip: !organizerId,
    variables: {
      productUuid,
      status,
    },
  });
  const [value, setValue] = useState<Maybe<string>>(initValue || null);

  const handleChange = (val: Maybe<string>) => {
    setValue(val);
    onChange(val);
  };

  const items = useMemo(() => {
    const result = data?.tariffsList.rows || [];

    if (hideTariffType?.length) {
      return result.filter((el) => !hideTariffType.includes(el.type));
    }

    return result;
  }, [data?.tariffsList.rows]);

  useEffect(() => {
    if (items.length > 0 && autoSetFirst) {
      handleChange(items[0].uuid);
    }
  }, [items]);

  return (
    <div className={styles.tariffPicker}>
      {!hideAll && (
        <Chip onClick={() => handleChange(null)} active={!value}>
          Все тарифы
        </Chip>
      )}

      {items.map((el) => (
        <Chip
          key={el.uuid}
          onClick={() => handleChange(el.uuid)}
          active={value === el.uuid}>
          {el.name}
        </Chip>
      ))}
    </div>
  );
};
