import React, { FC, useEffect, useState } from 'react';

import { TariffInput, TariffType } from '@/apolloGenerated';
import { ActionButtons, ApolloErrorService, Container } from '@/shared';
import {
  getTariffInitState,
  TariffForm,
  useAccessTariffUsers,
  useCreateTariff,
} from '@entities/Tariff';
import { ContactTariffAccessSelect } from '@features/Contact';
import { LoaderOverlay, Spacer, toaster } from '@letsdance/ui-kit';
import { Head } from '@widgets/Layout';

export interface TariffCreateProps {
  onClose(): void;
  onSuccess(val: TariffType): void;
}
export const TariffCreate: FC<TariffCreateProps> = ({ onClose, onSuccess }) => {
  const [createTariff, { data: updateData, error, loading: saveLoading }] =
    useCreateTariff();
  const [updateUserAccessTariff, { loading: saveAccessLoading }] =
    useAccessTariffUsers();
  const [tariffData, setTariffData] = useState<TariffInput>();
  const [usersAccessUuids, setUserAccessUuids] = useState<string[]>([]);

  const handleSubmit = () => {
    if (!tariffData) {
      return toaster.error({ title: 'Отсутствуют данные для тарифа' });
    }
    createTariff({
      input: tariffData,
    });
  };

  useEffect(() => {
    if (updateData) {
      onSuccess(updateData.createTariff);
      updateUserAccessTariff({
        userUuids: usersAccessUuids,
        uuid: updateData.createTariff.uuid,
      });
    }
  }, [updateData]);

  return (
    <div>
      <LoaderOverlay show={saveLoading || saveAccessLoading} />
      <Container>
        <Head title="Новый тариф" />
      </Container>
      <ActionButtons
        actions={[
          { handler: onClose, label: 'Отменить' },
          { handler: handleSubmit, label: 'Сохранить изменения' },
        ]}
      />
      <Spacer size={10} />
      <Container>
        <TariffForm
          initValues={getTariffInitState() as TariffType}
          onChange={setTariffData}
          errors={
            ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
              ?.validationErrors
          }
        />
        <ContactTariffAccessSelect onChange={setUserAccessUuids} />
      </Container>
    </div>
  );
};
