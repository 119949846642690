import React, { FC, useEffect } from 'react';

import {
  TariffFeatureFlagsInput,
  TariffInput,
  TariffType,
  TariffTypeEnum,
} from '@/apolloGenerated';
import { FormSection, handleNumberInput } from '@/shared';
import { TariffTypeTranslate } from '@entities/Tariff/const';
import {
  DateTimePicker,
  DropdownItem,
  NestedKeys,
  Select,
  Spacer,
  Switch,
  TextField,
  Typography,
  useForm,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface TariffFormProps {
  initValues: TariffType;
  onChange(val: TariffInput): void;
  errors?: Record<NestedKeys<TariffInput>, string[]>;
  readonly?: boolean;
}
export const TariffForm: FC<TariffFormProps> = ({
  errors,
  initValues,
  onChange,
  readonly,
}) => {
  const availableOrdersText = initValues.availableOrdersCount
    ? `(Доступно: ${initValues.availableOrdersCount})`
    : '';
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    onChange: onChangeValue,
    setBatchValues,
    values,
  } = useForm<TariffInput>(console.log, initValues);

  useEffect(() => {
    onChange(values);
  }, [values]);

  const updateFeatureFlags = function <T extends keyof TariffFeatureFlagsInput>(
    key: T,
    val: TariffFeatureFlagsInput[T],
  ) {
    setBatchValues({
      featureFlags: {
        ...values.featureFlags,
        [key]: val,
      },
    });
  };

  return (
    <div className={styles.tariffForm}>
      <FormSection title="Основная информация">
        <div className={styles.tariffForm__mainInfo}>
          <TextField
            label="Название тарифа"
            size="large"
            disabled={readonly}
            fullWidth
            error={errors?.name}
            name="name"
            initValue={values.name!}
            onChange={onChangeValue}
          />
          <TextField
            label="Цена тарифа, ₽"
            size="large"
            disabled={readonly}
            fullWidth
            error={errors?.price}
            name="price"
            onKeyPress={handleNumberInput}
            initValue={String(values.price)}
            onChange={(e) => setBatchValues({ price: +e.target.value })}
          />
        </div>
        <Spacer size={10} />
        <TextField
          tag="textarea"
          label="Описание"
          size="large"
          style={{ height: 200 }}
          disabled={readonly}
          fullWidth
          error={errors?.description}
          name="description"
          initValue={values.description!}
          onChange={onChangeValue}
        />
        <Spacer size={10} />
        <Switch
          label="Видимый"
          checked={values.visible}
          disabled={readonly}
          onChange={(e) => setBatchValues({ visible: e.target.checked })}
        />
      </FormSection>

      <FormSection title="Фичи">
        <div className="grid-2">
          <div>
            <Switch
              label="Возврат билета + оплата бонусными баллама"
              checked={values.featureFlags.refundOrder}
              disabled={readonly}
              onChange={(e) =>
                updateFeatureFlags('refundOrder', e.target.checked)
              }
            />
            <Spacer size={10} />
            <Switch
              label="Передача билета"
              checked={values.featureFlags.transferToUserOrder}
              disabled={readonly}
              onChange={(e) =>
                updateFeatureFlags('transferToUserOrder', e.target.checked)
              }
            />
          </div>
          <div>
            <Switch
              label="Перенос билета на другие события"
              checked={values.featureFlags.transferToProductOrder}
              disabled={readonly}
              onChange={(e) =>
                updateFeatureFlags('transferToProductOrder', e.target.checked)
              }
            />
            <Spacer size={10} />
            <Switch
              label="Повторная покупка"
              checked={values.featureFlags.repeatBuyOrder}
              disabled={readonly}
              onChange={(e) =>
                updateFeatureFlags('repeatBuyOrder', e.target.checked)
              }
            />
          </div>
        </div>
        <Spacer size={10} />
      </FormSection>
      <FormSection title="Доступы">
        <div className="grid-2">
          <TextField
            label={`Кол-во билетов ${availableOrdersText}`}
            size="large"
            disabled={readonly}
            fullWidth
            error={errors?.ordersCount}
            name="ordersCount"
            onKeyPress={handleNumberInput}
            initValue={String(values.ordersCount || '')}
            onChange={(e) =>
              setBatchValues({ ordersCount: +e.target.value || null })
            }
          />
          <Select
            size="large"
            placeholder="Тип билета"
            name="type"
            initValue={values.type as TariffTypeEnum}
            renderValue={(val) => TariffTypeTranslate[val as TariffTypeEnum]}
            disabled={readonly}
            onChangeNative={onChangeValue}
            hideOnSelect
            fullWidth>
            {Object.entries(TariffTypeTranslate).map(([key, label]) => (
              <DropdownItem key={key} label={label} value={key} />
            ))}
          </Select>
        </div>
        <Spacer size={10} />
        <div className="grid-2">
          <DateTimePicker
            initValue={values.startedDate ? new Date(values.startedDate) : null}
            label={`Дата активации (${timeZone})`}
            fullWidth
            size="large"
            onChange={(val) =>
              setBatchValues({ startedDate: val ? val.toISOString() : null })
            }
            error={errors?.startedDate}
            disabled={readonly}
            minDate={new Date()}
          />
          <DateTimePicker
            initValue={values.endDate ? new Date(values.endDate) : null}
            label={`Дата деактивации (${timeZone})`}
            fullWidth
            size="large"
            onChange={(val) =>
              setBatchValues({ endDate: val ? val.toISOString() : null })
            }
            error={errors?.endDate}
            disabled={readonly}
            minDate={
              values?.startedDate ? new Date(values.startedDate) : new Date()
            }
          />
        </div>
        <Spacer size={10} />
        <Typography variant="body-14" color="on-surface-primary-2">
          Если заполнено поле "Дата активации", то после сохранения тариф будет
          помещён в "Черновик" и станет доступен только в указанное время
        </Typography>
      </FormSection>
    </div>
  );
};
