import { useMemo } from 'react';

import {
  UsersSpecialTariffAccessListQuery,
  UserType,
  useUsersByGroupTariffAccessListQuery,
  useUsersSpecialTariffAccessListQuery,
  useUsersTariffAccessListQuery,
} from '@/apolloGenerated';
import { formatSort, useOrganizerId } from '@/shared';
import { ContactSelectTablePayload } from '@entities/Contact';
import { ContactTariffAccessSelectTabs } from '@features/Contact';
import { ContactTariffAccessSelectTabsEnum } from '@features/Contact/ui/ContactTariffAccessSelect/ContactTariffAccessSelect';

export interface TariffSelectUsersListResult {
  data: UserType[];
  loading: boolean;
  total: number;
  pageSize: number;
}
const pageSize = 8;

export const useTariffSelectUsersList = (
  type: ContactTariffAccessSelectTabs,
  payload: ContactSelectTablePayload,
): TariffSelectUsersListResult => {
  const organizerId = useOrganizerId()!;
  const baseVariables = useMemo(
    () => ({
      filters: {
        order: { sort: formatSort(payload.sort), sortBy: payload.sortBy },
        pagination: { page: payload.page, pageSize },
        search: payload.filter.search,
      },
      organizerId,
    }),
    [payload],
  );
  const skipBase = !organizerId;
  const skipType = (curType: ContactTariffAccessSelectTabs) => type !== curType;
  const allResult = useUsersTariffAccessListQuery({
    skip: skipBase || skipType(ContactTariffAccessSelectTabsEnum.All),
    variables: baseVariables,
  });
  const specResult = useUsersSpecialTariffAccessListQuery({
    skip: skipBase || skipType(ContactTariffAccessSelectTabsEnum.Special),
    variables: baseVariables,
  });
  const groupResult = useUsersByGroupTariffAccessListQuery({
    skip:
      skipBase ||
      Object.values(ContactTariffAccessSelectTabsEnum).includes(type),
    variables: {
      ...baseVariables,
      groupUuid: type,
    },
  });
  const curData = useMemo<
    UsersSpecialTariffAccessListQuery['specialUsers']
  >(() => {
    if (type === ContactTariffAccessSelectTabsEnum.Special) {
      return (specResult.data?.specialUsers ||
        specResult.previousData
          ?.specialUsers) as UsersSpecialTariffAccessListQuery['specialUsers'];
    }
    if (type === ContactTariffAccessSelectTabsEnum.All) {
      return (allResult.data?.users ||
        allResult.previousData
          ?.users) as UsersSpecialTariffAccessListQuery['specialUsers'];
    }

    return (groupResult.data?.usersByGroup ||
      groupResult.previousData
        ?.usersByGroup) as UsersSpecialTariffAccessListQuery['specialUsers'];
  }, [specResult, allResult, groupResult, type]);

  return {
    data: (curData?.rows || []) as UserType[],
    loading: specResult.loading || allResult.loading || groupResult.loading,
    pageSize,
    total: curData?.count || 0,
  };
};
