import React, { FC } from 'react';

import { Checkbox, Tooltip } from '@letsdance/ui-kit';

export interface TableCellCheckboxProps {
  value?: boolean;
  onChange?(val: boolean): void;
  disabled?: boolean;
  tooltipText?: string;
}
export const TableCellCheckbox: FC<TableCellCheckboxProps> = ({
  disabled,
  onChange,
  tooltipText,
  value,
}) => (
  <Tooltip
    className="flex"
    label={tooltipText || ''}
    disabled={!disabled || !tooltipText}
    zIndex={1000}>
    <Checkbox
      checked={value}
      onChange={(event) => {
        event.stopPropagation();
        onChange && onChange(event.target.checked);
      }}
      disabled={disabled}
    />
  </Tooltip>
);

export const renderCellCheckbox = (args: TableCellCheckboxProps) => (
  <TableCellCheckbox {...args} />
);
