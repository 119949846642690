import { FC } from 'react';

import { Container } from '@/shared';
import { Button, ButtonProps, Divider } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface ActionButton {
  label: string;
  handler(): void;
  disabled?: boolean;
  size?: ButtonProps['size'];
  color?: ButtonProps['color'];
}
export interface ActionButtonsProps {
  actions: ActionButton[];
  hideDivider?: boolean;
}
export const ActionButtons: FC<ActionButtonsProps> = ({
  actions,
  hideDivider,
}) => (
  <div className={styles.driverAction}>
    {actions.length > 0 && (
      <Container className={styles.driverAction__actions}>
        {actions.map(({ color, disabled, handler, label, size }, index) => (
          <Button
            key={index}
            size={size || 'small'}
            color={color || 'secondary'}
            disabled={disabled}
            onClick={handler}>
            {label}
          </Button>
        ))}
      </Container>
    )}
    {!hideDivider && <Divider className={styles.driverAction__hr} />}
  </div>
);
