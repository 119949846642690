import { GenderEnum, TariffTypeEnum, UserType } from '@/apolloGenerated';
import { isRegisteredUser } from '@entities/Contact/libs/helpers';
import { Maybe } from '@letsdance/ui-kit';

export interface ValidationError {
  status: boolean;
  message?: string;
}
export const validateTariffGender = (
  userGender?: Maybe<GenderEnum>,
  type?: TariffTypeEnum,
): ValidationError => {
  if (type === TariffTypeEnum.Female && userGender !== GenderEnum.Female) {
    return {
      message: 'Тариф доступен только для женского пола',
      status: false,
    };
  }
  if (type === TariffTypeEnum.Male && userGender !== GenderEnum.Male) {
    return {
      message: 'Тариф доступен только для мужского пола',
      status: false,
    };
  }
  if (type === TariffTypeEnum.Pair) {
    return {
      message: 'Нельзя пригласить пользователя на парный тариф',
      status: false,
    };
  }

  return {
    status: true,
  };
};
export const validateProductHasOrder = (
  hasOrderByProduct?: boolean,
): ValidationError => {
  if (hasOrderByProduct) {
    return {
      message: 'Пользователь уже имеет билет на данное событие',
      status: false,
    };
  }

  return {
    status: true,
  };
};
export const validateProductHasInvite = (
  hasInviteByProduct?: boolean,
): ValidationError => {
  if (hasInviteByProduct) {
    return {
      message: 'Пользователь уже имеет приглашение на данное событие',
      status: false,
    };
  }

  return {
    status: true,
  };
};
export const validateRegistered = function <
  T extends Pick<UserType, 'gender' | 'email' | 'phone'>,
>(userInfo: T): ValidationError {
  if (!isRegisteredUser(userInfo)) {
    return {
      message: 'Пользователь не заполнил свои данные',
      status: false,
    };
  }

  return {
    status: true,
  };
};

export interface ValidateTariffData<
  TUser extends Pick<UserType, 'gender' | 'email' | 'phone'>,
> {
  userInfo: TUser;
  tariffType?: TariffTypeEnum;
  hasOrderByProduct?: boolean;
  hasInviteByProduct?: boolean;
}
export interface ValidateTariffParams {
  skipRegistered?: boolean;
  skipCheckInvite?: boolean;
}
export const validateTariff = function <
  TUser extends Pick<UserType, 'gender' | 'email' | 'phone'>,
>(
  {
    hasInviteByProduct,
    hasOrderByProduct,
    tariffType,
    userInfo,
  }: ValidateTariffData<TUser>,
  params?: ValidateTariffParams,
): ValidationError {
  const validateHasOrder = validateProductHasOrder(hasOrderByProduct);

  if (!validateHasOrder.status) {
    return validateHasOrder;
  }

  if (!params?.skipRegistered) {
    const validationRegisteredError = validateRegistered(userInfo);

    if (!validationRegisteredError.status) {
      return validationRegisteredError;
    }
  }

  if (!params?.skipCheckInvite) {
    const validationInviteError = validateProductHasInvite(hasInviteByProduct);

    if (!validationInviteError.status) {
      return validationInviteError;
    }
  }

  return validateTariffGender(userInfo.gender, tariffType);
};
