import { OrganizerInput } from '@/apolloGenerated';

export const getOrganizerInput = ({
  description,
  image,
  name,
  query,
  socials: {
    facebook,
    image: imageSocial,
    instagram,
    telegram: tgSocial,
    text,
    vk,
    youtube,
  },
  supplierInfo: { inn, name: supplierName, phone },
  telegram,
  termsOfUse,
}: OrganizerInput): OrganizerInput => ({
  description,
  image,
  name,
  query,
  socials: {
    facebook,
    image: imageSocial,
    instagram,
    telegram: tgSocial,
    text,
    vk,
    youtube,
  },
  supplierInfo: {
    inn,
    name: supplierName,
    phone,
  },
  telegram,
  termsOfUse,
});
