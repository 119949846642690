import { ProductInput, ProductType } from '@/apolloGenerated';

export const getEventInput = ({
  activeAcquiring,
  address,
  description,
  endDate,
  faq,
  featureFlags: {
    allowedNumberForTransferToProductOrder,
    durationBeforeStartForRefundOrder,
    durationBeforeStartForTransferToUserOrder,
    refundOrder,
    repeatBuyOrder,
    transferFromProductOrder,
    transferToProductOrder,
    transferToUserOrder,
  },
  img,
  invoiceLifetime,
  manualBuyText,
  name,
  preview: { active, backButtonText, description: descriptionPreview, url },
  query,
  route: {
    active: activeRoute,
    backButtonText: backButtonTextRoute,
    description: descriptionRoute,
    url: urlRoute,
  },
  salesStartedDate,
  startedDate,
  successBuyText,
  tariffsEmptyText,
  tariffsText,
  tariffsUuid,
  transferUuidTariff,
}: ProductInput): ProductInput => ({
  activeAcquiring,
  address,
  description,
  endDate,
  faq,
  featureFlags: {
    allowedNumberForTransferToProductOrder,
    durationBeforeStartForRefundOrder,
    durationBeforeStartForTransferToUserOrder,
    refundOrder,
    repeatBuyOrder,
    transferFromProductOrder,
    transferToProductOrder,
    transferToUserOrder,
  },
  img,
  invoiceLifetime,
  manualBuyText,
  name,
  preview: {
    active,
    backButtonText,
    description: descriptionPreview,
    url,
  },
  query,
  route: {
    active: activeRoute,
    backButtonText: backButtonTextRoute,
    description: descriptionRoute,
    url: urlRoute,
  },
  salesStartedDate,
  startedDate,
  successBuyText,
  tariffsEmptyText,
  tariffsText,
  tariffsUuid,
  transferUuidTariff,
});

export const getEventInitState = (): ProductInput &
  Pick<ProductType, 'tariffs'> => ({
  activeAcquiring: true,
  address: null,
  description: '',
  endDate: new Date().toISOString(),
  faq: '',
  featureFlags: {
    allowedNumberForTransferToProductOrder: 3,
    durationBeforeStartForRefundOrder: 'P0D',
    durationBeforeStartForTransferToUserOrder: 'P0D',
    refundOrder: true,
    repeatBuyOrder: true,
    transferFromProductOrder: true,
    transferToProductOrder: true,
    transferToUserOrder: true,
  },
  img: '',
  invoiceLifetime: 'PT1H',
  manualBuyText: null,
  name: '',
  preview: {
    active: false,
    backButtonText: null,
    description: null,
    url: null,
  },
  query: null,
  route: {
    active: false,
    backButtonText: null,
    description: null,
    url: null,
  },
  salesStartedDate: null,
  startedDate: new Date().toISOString(),
  successBuyText: null,
  tariffs: [],
  tariffsEmptyText: null,
  tariffsText: null,
  tariffsUuid: [],
  transferUuidTariff: null,
});
