import { createElement } from 'react';

import { Cart, Diagram, EmojiHappy, ReceiptAdd } from '../ui/icons';

export const STAT = [
  [
    'от',
    '12%',
    null,
    'Увеличение <br /> среднего чека',
    createElement(ReceiptAdd),
  ],
  [
    'на',
    '85%',
    null,
    'Снижение нагрузки <br /> по администрированию',
    createElement(EmojiHappy),
  ],
  ['в', '3', 'раза', 'Сокращение <br /> цикла продаж', createElement(Cart)],
  ['на', '33%', null, 'Увеличение конверсии', createElement(Diagram)],
];

export const BY_MESSENGERS = [
  'Клиенты могут самостоятельно выбрать и оплатить курс в Telegram боте',
  'Автоматический контроль доступа пользователей в закрытые Telegram каналы и чаты с материалами',
  'Рассылка индивидуальных предложений клиентам',
];

export const STATISTIC = [
  'Менеджмент клиентской базы с помощью встроенной CRM системы',
  'Статистика заказов по каждому клиенту и анализ их поведения',
];

export const LOYALTY_PROGRAM = [
  'Увеличение выручки за счёт кэшбэк-сервисов и реферальной системы для дополнительных продаж',
  'Создание промокодов и скидок, кешбэк-система',
  'Реферальные программы для танцевальных информационных порталов, школ танцев',
];

export const INTEGRATION = [
  'Размещение информации о курсе \nна информационном портале \n«Где танцевать»',
  'Дополнительный трафик людей \nдля продажи курсов',
];

export const ROLES = [
  [
    'Я организатор',
    'и хочу автоматизировать продажи, учёт и онлайн-запись гостей',
    'фестиваля',
  ],
  [
    'Я организатор',
    'и хочу автоматизировать продажи, учёт и онлайн-запись гостей',
    'вечеринки',
  ],
  [
    'Я <a href="https://h2.dance/" target="_blank" rel="noopener noreferrer">преподаватель</a> танцев',
    'и запускаю онлайн курсы',
    null,
  ],
];

export const AUTOMATION = [
  [
    'Онлайн-продажа билетов',
    'Настройте приём оплаты напрямую в Telegram боте в несколько кликов. Продавайте билеты и регистрируйте участников на мероприятия 24/7.',
    true,
  ],
  [
    'Интеграция с ботом в Telegram',
    'Для привлечения новых пользователей и удобства текущих, взаимодействуйте с ними напрямую через бота в Telegram.',
  ],
  [
    'Статистика и аналитика',
    'Управляйте базой пользователей через встроенную CRM. Следите за заказами и анализируйте действия каждого участника, пока этого не начали делать конкуренты.',
  ],
  [
    'База клиентов',
    'Сохраняйте полную историю посещений, статус, карту клиента и его предпочтения. Сегментируйте базу и отправляйте персональные и групповые предложения для клиентов',
  ],
  [
    'Программы лояльности',
    'Зарабатывайте больше с кэшбэком и рефералками для дополнительных продаж. Создавайте промокоды, скидки и кэшбэк-систему.',
  ],
];

export const OFFERS = [
  [
    'Организатор фестивалей',
    'Планируйте расписание, управляйте онлайн-продажами билетов, собирайте статистику о фестивале',
    false,
  ],
  [
    'Организатор вечеринок',
    'Настройте онлайн-продажи билетов, управляйте базой клиентов и спец гостей, анализируйте статистику о мероприятии',
    false,
  ],
  [
    'Преподаватель танцев',
    'Автоматизируйте запуск онлайн курсов через Telegram бота, отслеживайте статистику продаж',
    true,
  ],
];
