import { FC, useContext } from 'react';

import { PurchasesContext, PurchasesContextType } from '@entities/Purchases';
import { EventPicker } from '@features/Event';

export const PurchasesProductPicker: FC = () => {
  const { productUuid, setProductUuid } =
    useContext<PurchasesContextType>(PurchasesContext);

  return <EventPicker initValue={productUuid} onChange={setProductUuid} />;
};
