import React, { FC, useEffect } from 'react';

import {
  useRemoveUserFromGroupMutation,
  useUserQuery,
} from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface RemoveUserFromGroupModalProps {
  uuidUser: string;
  uuidGroup: string;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    removeUserFromGroup: RemoveUserFromGroupModalProps;
  }
}
export const RemoveUserFromGroupModal: FC<
  WithCloseModal<RemoveUserFromGroupModalProps>
> = ({ onClose, onSuccess, uuidGroup, uuidUser }): JSX.Element => {
  const organizerId = useOrganizerId()!;
  const { data: userData } = useUserQuery({
    variables: { organizerId, uuid: uuidUser },
  });
  const userVisibleName =
    userData?.user?.username ||
    `${userData?.user?.first_name}${userData?.user?.last_name ? ' ' + userData?.user?.last_name : ''}`;
  const [removeUserFromGroup, { client, data, error, loading }] =
    useRemoveUserFromGroupMutation();

  const handleSubmit = () => {
    removeUserFromGroup({ variables: { uuidGroup, uuidUser } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: `Пользователь ${userVisibleName} успешно удалён из группы`,
      });
      client.cache.evict({ fieldName: 'userGroups' });
      client.cache.evict({
        fieldName: 'usersByGroup',
      });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title:
          'Ошибка удаления пользователя из группы. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title={`Удалить пользователя "${userVisibleName}" из группы?`}
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button
                fullWidth
                color="danger"
                onClick={handleSubmit}
                disabled={loading}>
                Удалить
              </Button>
            </div>
          }
        />
      }
    />
  );
};
