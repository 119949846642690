import { useMemo } from 'react';

import {
  UserSortKeys,
  useUsersBlockedListQuery,
  useUsersByGroupListQuery,
  useUsersListQuery,
  useUsersSpecialListQuery,
} from '@/apolloGenerated';
import { formatSort, useOrganizerId } from '@/shared';
import { ContactTabs, ContactTabsEnum } from '@entities/Contact';
import { Maybe, SortType } from '@letsdance/ui-kit';

export interface UsersListHookProps {
  type?: ContactTabs;
  search: string;
  sort: Maybe<SortType>;
  page: number;
  pageSize: number;
  sortBy: UserSortKeys;
}
export const useUsersList = ({
  page,
  pageSize,
  search,
  sort,
  sortBy,
  type,
}: UsersListHookProps) => {
  const organizerId = useOrganizerId()!;
  const filters = {
    order: { sort: formatSort(sort), sortBy },
    pagination: { page, pageSize },
    search,
  };
  const resultUsers = useUsersListQuery({
    skip:
      !organizerId ||
      ![ContactTabsEnum.Special, ContactTabsEnum.All].includes(type!),
    variables: {
      filters,
      isActive: type === ContactTabsEnum.Active,
      organizerId,
    },
  });
  const resultSpecial = useUsersSpecialListQuery({
    skip: !organizerId || type !== ContactTabsEnum.Special,
    variables: {
      filters,
      organizerId,
    },
  });
  const resultBlocked = useUsersBlockedListQuery({
    skip: !organizerId || type !== ContactTabsEnum.Blocked,
    variables: {
      filters,
      organizerId,
    },
  });
  const resultGroup = useUsersByGroupListQuery({
    skip: !organizerId || Object.values(ContactTabsEnum).includes(type!),
    variables: {
      filters,
      groupUuid: type!,
      organizerId,
    },
  });
  const loading =
    resultUsers.loading ||
    resultSpecial.loading ||
    resultBlocked.loading ||
    resultGroup.loading;

  const data = useMemo(() => {
    if (type === ContactTabsEnum.Special) {
      return (
        resultSpecial.data?.specialUsers ||
        resultSpecial.previousData?.specialUsers
      );
    }
    if (type === ContactTabsEnum.Blocked) {
      return (
        resultBlocked.data?.blacklist || resultBlocked.previousData?.blacklist
      );
    }
    if ([ContactTabsEnum.Special, ContactTabsEnum.All].includes(type!)) {
      return resultUsers.data?.users || resultUsers.previousData?.users;
    }

    return (
      resultGroup.data?.usersByGroup || resultGroup.previousData?.usersByGroup
    );
  }, [type, resultSpecial, resultUsers, resultBlocked, resultGroup]);

  return { data, loading };
};
