import { useMemo } from 'react';

import {
  UsersRegisteredProductListQuery,
  UsersSpecialProductListQuery,
  UserType,
  useUsersRegisteredProductListQuery,
  useUsersSpecialProductListQuery,
} from '@/apolloGenerated';
import { formatSort, useOrganizerId } from '@/shared';
import { ContactSelectTablePayload } from '@entities/Contact';
import { ContactOrderInviteSelectTabs } from '@features/Contact';
import { Maybe } from '@letsdance/ui-kit';

export interface ContactSelectUsersListResult {
  data: UserType[];
  loading: boolean;
  total: number;
  pageSize: number;
}
const pageSize = 10;

export const useContactSelectUsersList = (
  type: ContactOrderInviteSelectTabs,
  productUuid: Maybe<string | undefined>,
  payload: ContactSelectTablePayload,
): ContactSelectUsersListResult => {
  const organizerId = useOrganizerId()!;
  const baseVariables = useMemo(
    () => ({
      filters: {
        order: { sort: formatSort(payload.sort), sortBy: payload.sortBy },
        pagination: { page: payload.page, pageSize },
        search: payload.filter?.search,
      },
      organizerId,
      productUuid: productUuid!,
    }),
    [payload, productUuid],
  );
  const skipBase = !organizerId || !productUuid;
  const skipType = (curType: ContactOrderInviteSelectTabs) => type !== curType;
  const regResult = useUsersRegisteredProductListQuery({
    skip: skipBase || skipType(ContactOrderInviteSelectTabs.Registered),
    variables: baseVariables,
  });
  const specResult = useUsersSpecialProductListQuery({
    skip: skipBase || skipType(ContactOrderInviteSelectTabs.Special),
    variables: baseVariables,
  });
  const curData = useMemo<UsersSpecialProductListQuery['specialUsers']>(() => {
    if (type === ContactOrderInviteSelectTabs.Registered) {
      return (regResult.data?.users ||
        regResult.previousData
          ?.users) as UsersRegisteredProductListQuery['users'];
    }

    return (specResult.data?.specialUsers ||
      specResult.previousData
        ?.specialUsers) as UsersSpecialProductListQuery['specialUsers'];
  }, [regResult, specResult]);

  return {
    data: (curData?.rows || []) as UserType[],
    loading: regResult.loading || specResult.loading,
    pageSize,
    total: curData?.count || 0,
  };
};
