import { useEffect } from 'react';

import {
  CreateTariffMutation,
  CreateTariffMutationVariables,
  useCreateTariffMutation,
} from '@/apolloGenerated';
import { MutationResult } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

import { getTariffInput } from './getTariffInput';

export const useCreateTariff = (): [
  (vars: Pick<CreateTariffMutationVariables, 'input'>) => Promise<void>,
  MutationResult<CreateTariffMutation>,
] => {
  const [createTariff, result] = useCreateTariffMutation();

  const onCreateEvent = async ({
    input,
  }: Pick<CreateTariffMutationVariables, 'input'>) => {
    createTariff({
      variables: {
        input: getTariffInput(input),
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      const { client } = result;

      client.cache.evict({
        fieldName: 'tariffsList',
      });
      client.cache.evict({ fieldName: 'tariffs' });
      toaster.success({ title: 'Тариф успешно создан' });
    }
  }, [result.data, result.error]);

  return [onCreateEvent, result];
};
