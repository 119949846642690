import { useEffect } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  CreateEventMutation,
  CreateEventMutationVariables,
  Errors,
  useCreateEventMutation,
} from '@/apolloGenerated';
import { ApolloErrorService, useOrganizerId } from '@/shared';
import { MutationResult } from '@apollo/client';
import { RouterName } from '@app/router';
import { getEventInput } from '@entities/Event/libs/hooks/getEventInput';
import { toaster } from '@letsdance/ui-kit';

export const useCreateEvent = (): [
  (
    vars: Pick<CreateEventMutationVariables, 'input' | 'publish'>,
  ) => Promise<void>,
  MutationResult<CreateEventMutation>,
] => {
  const { navigate } = useNavigate();
  const organizerId = useOrganizerId()!;
  const [createEvent, result] = useCreateEventMutation();

  const onCreateEvent = async ({
    input,
    publish,
  }: Pick<CreateEventMutationVariables, 'input' | 'publish'>) => {
    createEvent({
      variables: {
        input: getEventInput(input),
        organizerId,
        publish,
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      const { client, data } = result;

      client.cache.evict({ fieldName: 'productsList' });
      client.cache.evict({ fieldName: 'productsCount' });
      navigate(RouterName.AdminEventEdit, {
        uuid: data.createProduct.uuid,
      });
      toaster.success({ title: 'Событие успешно создано' });
    }
    if (
      result.error &&
      ApolloErrorService.getFirstError(result.error?.graphQLErrors)?.message ===
        Errors.CanNotPublishProductWithoutTariff
    ) {
      toaster.error({
        title: 'Для публикации необходимо добавить хотя бы один тариф',
      });
    }
  }, [result.data, result.error]);

  return [onCreateEvent, result];
};
