import { FC } from 'react';

import { Container } from '@/shared';
import { RouterName } from '@app/router';
import { Button, PlusDenseIcon } from '@letsdance/ui-kit';
import { EventsLists } from '@widgets/Event';
import { Head } from '@widgets/Layout';

export const EventsView: FC = () => (
  <div>
    <Container>
      <Head
        title="Вечеринки"
        rightSlot={
          <Button to={RouterName.AdminEventCreate} prependIcon={PlusDenseIcon}>
            Создать вечеринку
          </Button>
        }
      />
    </Container>
    <EventsLists />
  </div>
);
