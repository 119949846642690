import { FC, useMemo, useState } from 'react';

import {
  Maybe,
  OrderType,
  PaymentsListQuery,
  PaymentSortKeys,
  usePaymentsListQuery,
} from '@/apolloGenerated';
import {
  calculateProfit,
  Container,
  formatAmount,
  formatSort,
  renderCellDate,
  renderCellItemValue,
  useOrganizerId,
} from '@/shared';
import { router, RouterName } from '@app/router';
import { OrderEventInfoCell } from '@entities/Purchases';
import {
  LoaderOverlay,
  SortType,
  TableController,
  Typography,
} from '@letsdance/ui-kit';

const headers = [
  { sortable: true, title: 'Дата', value: 'createdAt', width: 160 },
  { key: true, sortable: true, title: 'Операция', value: 'id', width: 160 },
  { title: 'Сведения', value: 'info' },
  { title: 'Контрагент', value: 'user' },
  { title: 'Оборот, ₽', value: 'turnover' },
  { title: 'Комиссия, ₽', value: 'commission' },
  { title: 'Прибыль, ₽ ', value: 'profit' },
];
const pageSize = 15;

export const ReceiptsTable: FC = () => {
  const organizerId = useOrganizerId()!;
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<PaymentSortKeys>(
    PaymentSortKeys.CreatedAt,
  );

  const {
    data: curData,
    loading,
    previousData,
  } = usePaymentsListQuery({
    skip: !organizerId,
    variables: {
      filter: {
        order: { sort: formatSort(sort), sortBy },
        pagination: { page, pageSize },
      },
      organizerId,
    },
  });

  const viewContact = (uuid: string) => {
    window.open(router.urlFor(RouterName.AdminContactEdit, { uuid }), '_blank');
  };

  const data = curData || previousData;
  const rowTemplate = ({
    createdAt,
    order,
    price,
    user,
  }: PaymentsListQuery['paymentsList']['rows'][0]) => {
    const { commission, profit, turnover } = calculateProfit(
      price,
      order.fixedHoldPercentage,
    );

    return {
      commission: formatAmount(commission),
      createdAt: renderCellDate({ time: true, timestamp: createdAt }),
      id: order.id,
      info: <OrderEventInfoCell order={order as OrderType} showPair />,
      profit: formatAmount(profit),
      turnover: formatAmount(turnover),
      user: renderCellItemValue({
        label: (
          <Typography
            variant="body-14"
            color="on-surface-primary-1"
            rel="noreferrer"
            className="cursor-pointer"
            onClick={() => viewContact(user.uuid)}>
            {user.last_name} {user.first_name}
          </Typography>
        ),
        value: user.email,
      }),
    };
  };
  const items = useMemo(
    () => (data?.paymentsList.rows || []).map(rowTemplate),
    [data],
  );

  return (
    <Container className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.paymentsList.count}
        onChangePage={setPage}
        pageSize={pageSize}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as PaymentSortKeys);
        }}
        notResetPage
      />
    </Container>
  );
};
