import React, { FC, useState } from 'react';

import { UserSortKeys, UserType, useUserGroupsQuery } from '@/apolloGenerated';
import { FormSection, useOrganizerId } from '@/shared';
import {
  ContactSelectTable,
  ContactSelectTablePayload,
  useTariffSelectUsersList,
} from '@entities/Contact';
import { Spacer, StarIcon, TabItem, Tabs } from '@letsdance/ui-kit';

export enum ContactTariffAccessSelectTabsEnum {
  All = 'all',
  Special = 'special',
}
export type ContactTariffAccessSelectTabs = ContactTariffAccessSelectTabsEnum &
  string;
export interface ContactTariffAccessSelectProps {
  initValue?: UserType[];
  onChange(uuids: string[]): void;
}
export const ContactTariffAccessSelect: FC<ContactTariffAccessSelectProps> = ({
  initValue,
  onChange,
}) => {
  const organizerId = useOrganizerId()!;
  const [payload, setPayload] = useState<ContactSelectTablePayload>({
    filter: { search: '' },
    page: 1,
    sort: 'desc',
    sortBy: UserSortKeys.CreatedAt,
  });
  const [tab, setTab] = useState<ContactTariffAccessSelectTabs>(
    ContactTariffAccessSelectTabsEnum.All,
  );
  const { data: usersGroupData } = useUserGroupsQuery({
    skip: !organizerId,
    variables: { filters: {}, idOrganizer: organizerId },
  });
  const { data, loading, pageSize, total } = useTariffSelectUsersList(
    tab,
    payload,
  );
  const [value, setValue] = useState<UserType[]>(initValue || []);

  const handleChange = (val: UserType[]) => {
    setValue(val);
    onChange(val.map((el) => el.uuid));
  };

  return (
    <FormSection
      title={`Пользователи (${value.length > 0 ? value.length : 'доступно всем'})`}>
      <Tabs initValue={tab} onChange={setTab}>
        <TabItem value={ContactTariffAccessSelectTabsEnum.All}>Все</TabItem>
        <TabItem
          style={{ alignItems: 'center', display: 'flex', gap: 4 }}
          value={ContactTariffAccessSelectTabsEnum.Special}>
          <StarIcon width={16} height={16} />
          Спецгости
        </TabItem>
        {(usersGroupData?.userGroups.rows || []).map((el) => (
          <TabItem
            key={el.uuid}
            style={{ alignItems: 'center', display: 'flex', gap: 8 }}
            value={el.uuid}>
            {el.name}
          </TabItem>
        ))}
      </Tabs>
      <Spacer size={8} />
      <ContactSelectTable
        selected={value}
        onChange={handleChange}
        data={data}
        total={total}
        loading={loading}
        onChangePayload={setPayload}
        pageSize={pageSize}
      />
    </FormSection>
  );
};
