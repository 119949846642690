import { Route } from 'react-justanother-router';

import { Role, RouterName } from '@app/router';
import * as views from '@pages/Admin';
import { AuthLayout, UserLayout } from '@widgets/Layout';

export const adminRoutes: Route[] = [
  {
    component: views.AuthView,
    name: RouterName.Authorization,
    path: '/login',
    props: {
      accessRole: Role.GHOST,
      layout: AuthLayout,
    },
  },
  {
    children: [
      {
        component: views.EventEditView,
        name: RouterName.AdminEventEdit,
        path: '/edit/:uuid',
        props: {
          accessRole: Role.USER,
          layout: UserLayout,
        },
      },
      {
        component: views.EventCreateView,
        name: RouterName.AdminEventCreate,
        path: '/create',
        props: {
          accessRole: Role.USER,
          layout: UserLayout,
        },
      },
    ],
    component: views.EventsView,
    name: RouterName.AdminEvents,
    path: '/events',
    props: {
      accessRole: Role.USER,
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: views.ContactEditView,
        name: RouterName.AdminContactEdit,
        path: '/edit/:uuid',
        props: {
          accessRole: Role.USER,
          layout: UserLayout,
        },
      },
    ],
    component: views.ContactView,
    name: RouterName.AdminContact,
    path: '/contacts',
    props: {
      accessRole: Role.USER,
      layout: UserLayout,
    },
  },
  {
    component: views.PurchasesView,
    name: RouterName.AdminPurchases,
    path: '/purchases',
    props: {
      accessRole: Role.USER,
      layout: UserLayout,
    },
  },
  {
    children: [
      {
        component: views.CreateWithdrawView,
        name: RouterName.AdminWithdrawCreate,
        path: '/withdraw',
        props: {
          accessRole: Role.USER,
          layout: UserLayout,
        },
      },
    ],
    component: views.FinanceView,
    name: RouterName.AdminFinance,
    path: '/finances',
    props: {
      accessRole: Role.USER,
      layout: UserLayout,
    },
  },
  {
    component: views.ProfileOrganizerView,
    name: RouterName.AdminProfile,
    path: '/profile',
    props: {
      accessRole: Role.USER,
      layout: UserLayout,
    },
  },
];
