import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const LogoIcon: FC<IconProps> = ({
  color = 'currentColor',
  height = 40,
  width = 87,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 87 40"
    fill="none"
    {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87 20C87 6.64308 78.9202 0 67.9029 0H0V6.05197H66.3653C75.1375 6.05197 80.7289 10.415 80.7289 20C80.7289 29.5795 75.1375 33.9487 66.3653 33.9487H52.9369C55.6824 30.3077 61.5209 28.3897 64.8461 25.2273C66.8034 23.3641 68.3778 20.8684 68.3778 17.4793C68.3778 14.1962 66.8736 11.6602 64.6838 10.0308C62.598 8.4783 60.5919 7.82358 58.2088 7.82358C52.4108 7.82358 46.2972 12.066 46.2972 20.0062H52.5578C52.5578 16.0561 55.3296 13.8725 58.2088 13.8725C60.1368 13.8725 62.1172 15.3986 62.1172 17.4824C62.1172 20.4239 58.4086 22.7282 56.2119 24.0376C51.3362 26.9709 46.3739 30.5333 46.3739 36.8615L46.3156 40H66.3622C79.0733 40 87 33.3573 87 20Z"
      fill="#FF457C"
    />
    <path
      d="M33.7754 29.812C33.4631 22.9505 31.272 12.3498 23.0125 12.3498C14.753 12.3498 12.5618 22.9505 12.2499 29.812H11.7524L6.3835 8.64147H0.0683374H0.0676533L9.14882 39.9898H15.3796C15.6111 38.9129 15.8031 37.2822 16.0257 35.3916C16.8317 28.5403 18.0427 18.2456 23.0125 18.2456C27.9823 18.2456 29.1936 28.5403 29.9996 35.3916C30.2222 37.2822 30.4142 38.9129 30.6457 39.9898H36.8766L45.9615 8.62573L39.6126 8.62609L34.2701 29.812H33.7754Z"
      fill={color}
    />
  </svg>
);
