import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  useFinanceStatsQuery,
  usePaymentAccountsListQuery,
  useWithdrawalRequestMutation,
} from '@/apolloGenerated';
import {
  ActionButtons,
  Container,
  formatAmount,
  handleNumberInput,
  useOrganizerId,
} from '@/shared';
import { history, router, RouterName } from '@app/router';
import { MINIMAL_WITHDRAW_AMOUNT } from '@entities/Withdraw';
import {
  Button,
  DropdownItem,
  Select,
  Spacer,
  TextField,
  toaster,
  Typography,
} from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';

import styles from '../FinanceView/styles.module.scss';

interface WithdrawProps {
  active: boolean;
}
export const CreateWithdrawView: FC<WithdrawProps> = () => {
  const { navigate } = useNavigate();
  const organizerId = useOrganizerId()!;

  const [withdrawalRequest, { client, data, error }] =
    useWithdrawalRequestMutation();
  const { data: financeData } = useFinanceStatsQuery({
    skip: !organizerId,
    variables: { organizerId },
  });
  const balance = financeData?.organizer.balance || 0;
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>('');
  const amountValue = useMemo(() => {
    if (!amount) {
      return '';
    }

    return isFocus ? amount : formatAmount(+amount);
  }, [amount, isFocus]);
  const { data: paymentsData } = usePaymentAccountsListQuery({
    skip: !organizerId,
    variables: { idOrganizer: organizerId },
  });
  const paymentItems = paymentsData?.paymentsByOrganizer || [];
  const [paymentUuid, setPaymentUuid] = useState<string>();

  const amountError = useMemo(() => {
    if (+amount && +amount > balance) {
      return 'Не хватает средств для вывода';
    }
    if (+amount && +amount < MINIMAL_WITHDRAW_AMOUNT) {
      return `Минимальная сумма для вывода ${formatAmount(MINIMAL_WITHDRAW_AMOUNT)}`;
    }
  }, [amount, balance]);

  const handleSubmit = () => {
    withdrawalRequest({
      variables: {
        input: {
          amount: +amount,
          organizerId,
          uuidPaymentAccount: paymentUuid!,
        },
      },
    });
  };

  const onClose = () => {
    history.navigate(router.urlFor(RouterName.AdminFinance));
  };

  useEffect(() => {
    if (data) {
      client.cache.evict({ fieldName: 'withdrawalsByOrganizer' });
      client.cache.evict({ fieldName: 'ordersStats' });
      toaster.success({ title: 'Заявка на вывод средств успешно создана' });
      onClose();
    }
    if (error) {
      toaster.error({
        title:
          'Ошибка создания запроса на оплату. Повторите попытку позже или обратитесь в техподдержку',
      });
    }
  }, [data, error]);

  const actions: ActionButton[] = [
    { handler: onClose, label: 'Отменить' },
    {
      disabled: !paymentUuid || !amount || !!amountError,
      handler: handleSubmit,
      label: 'Подтвердить вывод',
    },
  ];

  return (
    <>
      <Container slim>
        <Head
          title="Вывод средств"
          onBack={() => navigate(RouterName.AdminFinance)}
        />
      </Container>
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        <Select
          size="large"
          placeholder="Счёт для вывода"
          initValue={paymentUuid}
          renderValue={(val) =>
            paymentItems.find((el) => el.uuid === val)?.name || ''
          }
          onChange={setPaymentUuid}
          hideOnSelect
          fullWidth>
          {paymentItems.map((el) => (
            <DropdownItem key={el.uuid} label={el.name} value={el.uuid} />
          ))}
        </Select>
        <Spacer size={12} />
        <div className={styles.withdraw__labelWrap}>
          <Typography variant="body-16" color="on-surface-primary-1">
            Доступно для вывода
          </Typography>
          <Button
            size="small"
            color="secondary"
            onClick={() => setAmount(String(balance))}>
            {formatAmount(balance, true)} ₽
          </Button>
        </div>
        <Spacer size={6} />
        <TextField
          size="large"
          label="Сумма вывода, ₽"
          value={amountValue}
          onChange={(e) => setAmount(e.target.value)}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          onKeyPress={handleNumberInput}
          error={amountError}
          fullWidth
        />
      </Container>
    </>
  );
};
