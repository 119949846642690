import { gql } from '@apollo/client';
import { paymentAccountFragment } from '@shared/query/paymentAccount';

export const withdrawalFragment = gql`
  fragment WithdrawalFields on WithdrawalType {
    createdAt
    updatedAt
    deletedAt
    uuid
    amount
    status
    organizer {
      name
    }
  }
`;
export const withdrawalsList = gql`
  ${withdrawalFragment}
  ${paymentAccountFragment}
  query withdrawalsList(
    $filter: WithdrawalListFilterInput
    $idOrganizer: Int!
  ) {
    withdrawalsByOrganizer(idOrganizer: $idOrganizer, filters: $filter) {
      count
      rows {
        ...WithdrawalFields
        paymentAccount {
          ...PaymentAccountFields
        }
      }
    }
  }
`;
export const withdrawalRequest = gql`
  mutation withdrawalRequest($input: WithdrawalOrganizerInput!) {
    requestWithdrawal(input: $input)
  }
`;

export const cancelWithdrawal = gql`
  mutation cancelWithdrawal($idOrganizer: Int!, $uuid: UUID!) {
    cancelWithdrawal(idOrganizer: $idOrganizer, uuid: $uuid)
  }
`;
