import React, { FC, useMemo } from 'react';

import {
  StatusEnum,
  TariffTypeEnum,
  useTariffsListQuery,
} from '@/apolloGenerated';
import { DropdownItem, Maybe, Select, SelectProps } from '@letsdance/ui-kit';

export interface EventTariffTransferSelectProps {
  productUuid: string;
  disabled?: boolean;
  initValue?: Maybe<string>;
  placeholder?: string;
  name?: string;
  size?: SelectProps<string>['size'];
  onChange(val: string): void;
}
export const EventTariffTransferSelect: FC<EventTariffTransferSelectProps> = ({
  disabled,
  initValue,
  name,
  onChange,
  placeholder,
  productUuid,
  size,
}) => {
  const { data } = useTariffsListQuery({
    variables: {
      productUuid,
      status: [StatusEnum.Active, StatusEnum.Draft],
    },
  });

  const items = useMemo(
    () =>
      (data?.tariffsList.rows || []).filter(
        (el) => el.type === TariffTypeEnum.AnyGender && el.ordersCount === null,
      ),
    [data],
  );

  return (
    <Select
      key={items.length}
      size={size}
      placeholder={placeholder}
      name={name}
      initValue={initValue!}
      renderValue={(val) =>
        items.find((el) => el.uuid === val)?.name || (val as string)
      }
      disabled={disabled || items.length === 0}
      onChange={onChange}
      hideOnSelect
      fullWidth>
      {items.map((el) => (
        <DropdownItem key={el.uuid} label={el.name} value={el.uuid} />
      ))}
    </Select>
  );
};
