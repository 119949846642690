import React, { FC, MouseEvent, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { StatusEnum } from '@/apolloGenerated';
import { useModal } from '@/shared';
import { RouterName } from '@app/router';
import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  MoreIcon,
  Paper,
  stopEvent,
} from '@letsdance/ui-kit';

interface EventControlProps {
  uuid: string;
  status: StatusEnum;
  onInvite(): void;
  onCreateOrder(): void;
}

export const EventControl: FC<EventControlProps> = ({
  onCreateOrder,
  onInvite,
  status,
  uuid,
}) => {
  const { navigate } = useNavigate();
  const openArchiveModal = useModal('archiveEvent');
  const openRemoveModal = useModal('removeEvent');
  const openPublishModal = useModal('publishEvent');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isActive = status === StatusEnum.Active;
  const isDraft = status === StatusEnum.Draft;

  const handleChangeDropdown = (val: boolean) => {
    setIsOpen(val);
  };

  const eventCallback = (callback: () => void) => (e: MouseEvent) => {
    setIsOpen(false);
    stopEvent(e);
    callback();
  };

  const onEdit = () => {
    navigate(RouterName.AdminEventEdit, { uuid });
  };

  return (
    <Dropdown
      value={isOpen}
      targetSlot={
        <Button
          icon
          prependIcon={MoreIcon}
          variant="text"
          size="large"
          onClick={stopEvent}
        />
      }
      placement="bottom-end"
      width={260}
      zIndex={1000}
      onChange={handleChangeDropdown}>
      <Paper px={4} py={4} radius={6} className="paper-list">
        <DropdownItem
          fullWidth
          disabled={status === StatusEnum.Archive}
          onClick={eventCallback(onEdit)}
          label="Редактировать"
        />
        {isActive && (
          <DropdownItem
            fullWidth
            onClick={eventCallback(onCreateOrder)}
            label="Оформить билет"
          />
        )}
        {isActive && (
          <DropdownItem
            fullWidth
            onClick={eventCallback(onInvite)}
            label="Пригласить"
          />
        )}
        <DropdownItem
          fullWidth
          onClick={eventCallback(onEdit)}
          label="Дублировать"
        />
        {isActive && (
          <DropdownItem
            fullWidth
            onClick={eventCallback(() => openArchiveModal({ uuid }))}
            label="Перенести в архив"
          />
        )}
        {isDraft && (
          <DropdownItem
            fullWidth
            onClick={eventCallback(() => openPublishModal({ uuid }))}
            label="Опубликовать"
          />
        )}
        {isDraft && (
          <>
            <Divider className="divider-list" />
            <DropdownItem
              fullWidth
              onClick={eventCallback(() => openRemoveModal({ uuid }))}
              label="Удалить черновик"
            />
          </>
        )}
      </Paper>
    </Dropdown>
  );
};
