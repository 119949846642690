import { FC } from 'react';

import { useModal } from '@/shared';
import { Button, CloseDenseIcon } from '@letsdance/ui-kit';

export interface WithdrawCancelProps {
  uuid: string;
  onSuccess(): void;
}
export const WithdrawCancel: FC<WithdrawCancelProps> = ({
  onSuccess,
  uuid,
}) => {
  const openCancelWithdraw = useModal('cancelWithdraw');

  return (
    <Button
      icon
      prependIcon={CloseDenseIcon}
      variant="outlined"
      onClick={() =>
        openCancelWithdraw({
          onSuccess,
          uuid,
        })
      }
    />
  );
};
