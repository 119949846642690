import { FC } from 'react';

import { InputStep, InputSteps } from '@/shared';

export interface EventInputStepsProps {
  data?: any;
}
export const EventInputSteps: FC<EventInputStepsProps> = () => {
  const items: InputStep[] = [
    {
      active: true,
      label: 'Основная информация',
    },
    {
      active: false,
      label: 'Оплата',
    },
    {
      active: false,
      label: 'Фичи',
    },
    {
      active: false,
      label: 'Превью',
    },
    {
      active: false,
      label: 'Тарифы',
    },
    {
      active: false,
      label: 'Тексты',
    },
  ];

  return <InputSteps items={items} />;
};
