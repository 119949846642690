import React, { FC } from 'react';
import moment from 'moment-timezone';

import { TableCellLabelValue, timezone } from '@/shared';
import { Maybe } from '@letsdance/ui-kit';

export interface CourseNameCellProps {
  name: string;
  startedDate: string;
  query?: Maybe<string>;
}
export const EventNameCell: FC<CourseNameCellProps> = ({
  name,
  query,
  startedDate,
}) => (
  <TableCellLabelValue
    label={`${name} ${moment(startedDate).tz(timezone).format('DD.MM.YY')}`}
    value={query || '-'}
  />
);

export const renderCellEventName = (args: CourseNameCellProps) => (
  <EventNameCell {...args} />
);
