import { FC, PropsWithChildren } from 'react';

import { PropsWithNativeTypes, Spacer, Typography } from '@letsdance/ui-kit';

export interface FormSectionProps {
  title?: string;
  offBtmSpacing?: boolean;
  classNameContent?: string;
}
export const FormSection: FC<
  PropsWithChildren<PropsWithNativeTypes<FormSectionProps, HTMLDivElement>>
> = ({ children, classNameContent, offBtmSpacing, title, ...rest }) => (
  <section {...rest}>
    {title && (
      <>
        <Typography variant="head-24" color="on-surface-primary-1">
          {title}
        </Typography>
        <Spacer size={12} />
      </>
    )}
    <div className={classNameContent}>{children}</div>
    {!offBtmSpacing && <Spacer size={20} />}
  </section>
);
