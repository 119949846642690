import { FC } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Typography } from '@letsdance/ui-kit';

import { Bell, Clock, Collage } from '../icons';

import benefit from './styles.module.scss';

export const Benefit: FC = () => {
  const isMobile = useMediaQuery({ query: '(min-width: 1088px)' });

  return (
    <section>
      <div className={benefit.benefit}>
        <Typography
          variant={isMobile ? 'head-60' : 'head-32'}
          color={'on-surface-primary-1'}>
          Преимущества
        </Typography>
        <div className={benefit.list}>
          <div className={benefit.item}>
            <div className={benefit.wrapper__icon}>
              <Clock width={48} height={48} className={benefit.icon} />
            </div>
            <div className={benefit.group}>
              <Typography variant={'head-24'} color={'on-surface-primary-1'}>
                Быстрое внедрение
                <br /> за 1–3 дня
              </Typography>
              <Typography
                variant={isMobile ? 'body-20' : 'body-16'}
                color={'on-surface-primary-1'}
                className={benefit.desc}>
                Система «под ключ»
                <br /> не потребует доработок
              </Typography>
            </div>
          </div>
          <div className={benefit.item}>
            <div className={benefit.wrapper__icon}>
              <Collage width={48} height={48} className={benefit.icon} />
            </div>
            <div className={benefit.group}>
              <Typography variant={'head-24'} color={'on-surface-primary-1'}>
                Интуитивно понятный <br /> интерфейс
              </Typography>
              <Typography
                variant={isMobile ? 'body-20' : 'body-16'}
                color={'on-surface-primary-1'}
                className={benefit.desc}>
                Простой и информативный
                <br /> интерфейс, созданный <br /> танцорами для танцоров
              </Typography>
            </div>
          </div>
          <div className={benefit.item}>
            <div className={benefit.wrapper__icon}>
              <Bell width={48} height={48} className={benefit.icon} />
            </div>
            <div className={benefit.group}>
              <Typography variant={'head-24'} color={'on-surface-primary-1'}>
                Круглосуточная помощь
              </Typography>
              <Typography
                variant={isMobile ? 'body-20' : 'body-16'}
                color={'on-surface-primary-1'}
                className={benefit.desc}>
                Оперативная поддержка
                <br /> 24/7
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
