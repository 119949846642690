import { gql } from '@apollo/client';

export const user = gql`
  query User($uuid: UUID!, $organizerId: Float!) {
    user(uuid: $uuid) {
      createdAt
      updatedAt
      deletedAt
      uuid
      tgId
      username
      first_name
      last_name
      language
      role
      email
      phone
      ordersCount
      activatedOrdersCount
      receipts
      gender
      isSpecial(idOrganizer: $organizerId)
      isBlocked(organizerId: $organizerId)
    }
  }
`;

export const usersStats = gql`
  query usersStats($organizerId: Float, $isActive: Boolean) {
    usersStats(
      params: { organizerId: $organizerId }
      args: { isActive: $isActive }
    ) {
      count
      maleCount
      femaleCount
    }
  }
`;
export const updateUser = gql`
  mutation updateUser($uuid: UUID!, $input: UpdateUserInput!) {
    updateUser(uuid: $uuid, input: $input) {
      uuid
    }
  }
`;

export const userProductListFragment = gql`
  fragment UserProductListFields on UsersResponse {
    rows {
      uuid
      first_name
      last_name
      username
      phone
      email
      gender
      hasInviteByProduct(productUuid: $productUuid)
      hasOrderByProduct(productUuid: $productUuid)
      isSpecial(idOrganizer: $organizerId)
      isBlocked(organizerId: $organizerId)
    }
    count
  }
`;

export const usersRegisteredProductList = gql`
  ${userProductListFragment}
  query usersRegisteredProductList(
    $filters: UserListFilterInput
    $organizerId: Float!
    $productUuid: UUID!
  ) {
    users(
      filters: $filters
      params: { organizerId: $organizerId }
      args: { isRegistered: true }
    ) {
      ...UserProductListFields
    }
  }
`;

export const usersSpecialProductList = gql`
  ${userProductListFragment}
  query usersSpecialProductList(
    $filters: UserListFilterInput
    $organizerId: Float!
    $productUuid: UUID!
  ) {
    specialUsers(organizerId: $organizerId, filters: $filters) {
      ...UserProductListFields
    }
  }
`;

export const userTariffAccessListFragment = gql`
  fragment UserTariffAccessListFields on UsersResponse {
    rows {
      uuid
      first_name
      last_name
      username
      phone
      email
      gender
      isSpecial(idOrganizer: $organizerId)
      isBlocked(organizerId: $organizerId)
    }
    count
  }
`;

export const usersSpecialTariffAccessList = gql`
  ${userTariffAccessListFragment}
  query usersSpecialTariffAccessList(
    $filters: UserListFilterInput
    $organizerId: Float!
  ) {
    specialUsers(organizerId: $organizerId, filters: $filters) {
      ...UserTariffAccessListFields
    }
  }
`;

export const usersTariffAccessList = gql`
  ${userTariffAccessListFragment}
  query usersTariffAccessList(
    $filters: UserListFilterInput
    $organizerId: Float!
  ) {
    users(filters: $filters, params: { organizerId: $organizerId }, args: {}) {
      ...UserTariffAccessListFields
    }
  }
`;

export const usersByGroupTariffAccessList = gql`
  ${userTariffAccessListFragment}
  query usersByGroupTariffAccessList(
    $filters: UserListFilterInput!
    $groupUuid: UUID!
    $organizerId: Float!
  ) {
    usersByGroup(filters: $filters, groupUuid: $groupUuid) {
      ...UserTariffAccessListFields
    }
  }
`;
