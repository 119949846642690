import { useEffect } from 'react';

import {
  useAddUserToSpecialMutation,
  useRemoveUserFromSpecialMutation,
} from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import { toaster } from '@letsdance/ui-kit';

export const useSpecUserAction = () => {
  const organizerId = useOrganizerId()!;
  const [addSpecialUser, { client, data: addData, error: addError }] =
    useAddUserToSpecialMutation();
  const [removeSpecialUser, { data: removeData, error: removeError }] =
    useRemoveUserFromSpecialMutation();

  const updateCache = (userUuid: string) => {
    client.cache.evict({ fieldName: 'users' });
    client.cache.evict({ fieldName: 'specialUsers' });
    client.cache.evict({ args: { uuid: userUuid }, fieldName: 'user' });
  };

  const handleAddUser = (userUuid: string) =>
    addSpecialUser({ variables: { organizerId, userUuid } }).then(() =>
      updateCache(userUuid),
    );
  const handleRemoveUser = (userUuid: string) =>
    removeSpecialUser({ variables: { organizerId, userUuid } }).then(() =>
      updateCache(userUuid),
    );

  useEffect(() => {
    if (addData) {
      toaster.success({ title: 'Пользователь добавлен в спецгости' });
    }
    if (addError) {
      toaster.error({ title: 'Ошибка добавления пользователя в спецгости' });
    }
  }, [addData, addError]);

  useEffect(() => {
    if (removeData) {
      toaster.success({ title: 'Пользователь удалён из спецгостей' });
    }
    if (removeError) {
      toaster.error({ title: 'Ошибка удаления пользователя из спецгостей' });
    }
  }, [removeData, removeError]);

  return { onAddUser: handleAddUser, onRemoveUser: handleRemoveUser };
};
