import { useEffect } from 'react';

import {
  UpdateOrganizerMutation,
  UpdateOrganizerMutationVariables,
  useUpdateOrganizerMutation,
} from '@/apolloGenerated';
import { MutationResult } from '@apollo/client';
import { toaster } from '@letsdance/ui-kit';

import { getOrganizerInput } from './getOrganizerInput';

export const useUpdateOrganizer = (): [
  (vars: UpdateOrganizerMutationVariables) => Promise<void>,
  MutationResult<UpdateOrganizerMutation>,
] => {
  const [updateOrganizer, result] = useUpdateOrganizerMutation();

  const onUpdateOrganizer = async ({
    id,
    input,
  }: UpdateOrganizerMutationVariables) => {
    await updateOrganizer({
      variables: {
        id,
        input: getOrganizerInput(input),
      },
    });
  };

  useEffect(() => {
    if (result.data) {
      toaster.success({ title: 'Профиль успешно обновлён' });
      result.client.cache.evict({
        args: { id: result.data.updateOrganizer?.id },
        fieldName: 'organizer',
      });
    }
  }, [result.data, result.error]);

  return [onUpdateOrganizer, result];
};
