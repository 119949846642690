import { gql } from '@apollo/client';

export const userGroupFragment = gql`
  fragment UserGroupFields on UserGroupType {
    createdAt
    updatedAt
    uuid
    name
    usersCount
  }
`;

export const userListFragment = gql`
  fragment UserListFields on UsersResponse {
    rows {
      createdAt
      uuid
      first_name
      last_name
      username
      phone
      email
      receipts
      activatedOrdersCount
      ordersCount
      isSpecial(idOrganizer: $organizerId)
      isBlocked(organizerId: $organizerId)
    }
    count
  }
`;

export const usersList = gql`
  ${userListFragment}
  query usersList(
    $filters: UserListFilterInput
    $organizerId: Float!
    $isActive: Boolean
  ) {
    users(
      filters: $filters
      params: { organizerId: $organizerId }
      args: { isActive: $isActive }
    ) {
      ...UserListFields
    }
  }
`;

export const usersSpecialList = gql`
  ${userListFragment}
  query usersSpecialList($filters: UserListFilterInput, $organizerId: Float!) {
    specialUsers(organizerId: $organizerId, filters: $filters) {
      ...UserListFields
    }
  }
`;

export const usersBlockedList = gql`
  ${userListFragment}
  query usersBlockedList($filters: UserListFilterInput, $organizerId: Float!) {
    blacklist(organizerId: $organizerId, filters: $filters) {
      ...UserListFields
    }
  }
`;

export const usersByGroupList = gql`
  ${userListFragment}
  query usersByGroupList(
    $filters: UserListFilterInput!
    $groupUuid: UUID!
    $organizerId: Float!
  ) {
    usersByGroup(filters: $filters, groupUuid: $groupUuid) {
      ...UserListFields
    }
  }
`;

export const usersSpecialCount = gql`
  query usersSpecialCount($organizerId: Float!) {
    specialUsers(
      organizerId: $organizerId
      filters: { pagination: { page: 1, pageSize: 1 } }
    ) {
      count
    }
  }
`;

export const usersBlockedCount = gql`
  query usersBlockedCount($organizerId: Float!) {
    blacklist(
      organizerId: $organizerId
      filters: { pagination: { page: 1, pageSize: 1 } }
    ) {
      count
    }
  }
`;

export const userGroups = gql`
  ${userGroupFragment}
  query userGroups($filters: UserGroupsListFilterInput!, $idOrganizer: Float!) {
    userGroups(filters: $filters, idOrganizer: $idOrganizer) {
      rows {
        ...UserGroupFields
      }
      count
    }
  }
`;

export const addGroupUsers = gql`
  ${userGroupFragment}
  mutation addGroupUsers($idOrganizer: Float!, $name: String!) {
    crateUserGroup(idOrganizer: $idOrganizer, name: $name) {
      ...UserGroupFields
    }
  }
`;

export const updateGroupUsersName = gql`
  ${userGroupFragment}
  mutation updateGroupUsersName($uuid: UUID!, $name: String!) {
    updateUserGroupName(uuid: $uuid, name: $name) {
      ...UserGroupFields
    }
  }
`;

export const removeGroupUsers = gql`
  mutation removeGroupUsers($uuid: UUID!) {
    removeUserGroup(uuid: $uuid)
  }
`;

export const addUserToGroup = gql`
  mutation addUserToGroup($uuidUser: UUID!, $uuidGroup: UUID!) {
    addUserToGroup(uuidUser: $uuidUser, uuidGroup: $uuidGroup)
  }
`;

export const removeUserFromGroup = gql`
  mutation removeUserFromGroup($uuidUser: UUID!, $uuidGroup: UUID!) {
    removeUserFromGroup(uuidUser: $uuidUser, uuidGroup: $uuidGroup)
  }
`;

export const addUserToSpecial = gql`
  mutation addUserToSpecial($organizerId: Float!, $userUuid: UUID!) {
    addUserToSpecial(organizerId: $organizerId, userUuid: $userUuid) {
      uuid
    }
  }
`;

export const removeUserFromSpecial = gql`
  mutation removeUserFromSpecial($organizerId: Float!, $userUuid: UUID!) {
    removeUserFromSpecial(organizerId: $organizerId, userUuid: $userUuid)
  }
`;

export const blockUser = gql`
  mutation blockUser($organizerId: Float!, $userUuid: UUID!) {
    addUserToBlacklist(organizerId: $organizerId, uuid: $userUuid)
  }
`;

export const unblockUser = gql`
  mutation unblockUser($organizerId: Float!, $userUuid: UUID!) {
    removeUserFromBlacklist(organizerId: $organizerId, uuid: $userUuid)
  }
`;
