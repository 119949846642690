import React, { FC, useEffect, useMemo, useState } from 'react';

import { UserSortKeys, UserType, useTariffQuery } from '@/apolloGenerated';
import {
  ContactSelectTable,
  ContactSelectTablePayload,
  useContactSelectUsersList,
} from '@entities/Contact';
import { validateTariff } from '@entities/Tariff';
import { Maybe, Spacer, StarIcon, TabItem, Tabs } from '@letsdance/ui-kit';

export enum ContactOrderInviteSelectTabs {
  Special = 'special',
  Registered = 'registered',
}
export interface ContactOrderInviteSelectProps {
  tariffUuid?: Maybe<string>;
  productUuid?: Maybe<string>;
  onChange(uuids: string[]): void;
  requiredRegistration?: boolean;
  checkInvite?: boolean;
}
export const ContactOrderInviteSelect: FC<ContactOrderInviteSelectProps> = ({
  checkInvite,
  onChange,
  productUuid,
  requiredRegistration,
  tariffUuid,
}) => {
  const [payload, setPayload] = useState<ContactSelectTablePayload>({
    filter: { search: '' },
    page: 1,
    sort: 'desc',
    sortBy: UserSortKeys.CreatedAt,
  });
  const [tab, setTab] = useState<ContactOrderInviteSelectTabs>(
    ContactOrderInviteSelectTabs.Special,
  );
  const { data: tariffData } = useTariffQuery({
    skip: !tariffUuid,
    variables: { uuid: tariffUuid! },
  });
  const { data, loading, pageSize, total } = useContactSelectUsersList(
    tab,
    productUuid,
    payload,
  );
  const validationData = useMemo(
    () => ({ type: tariffData?.tariff.type }),
    [tariffData],
  );
  const [value, setValue] = useState<UserType[]>([]);

  const handleChange = (val: UserType[]) => {
    setValue(val);
    onChange(val.map((el) => el.uuid));
  };

  const handleReset = () => {
    setValue([]);
    onChange([]);
  };

  useEffect(() => {
    handleReset();
  }, [tab]);

  return (
    <div>
      <Tabs initValue={tab} onChange={setTab}>
        <TabItem
          style={{ alignItems: 'center', display: 'flex', gap: 4 }}
          value={ContactOrderInviteSelectTabs.Special}>
          <StarIcon width={16} height={16} />
          Спецгости
        </TabItem>
        <TabItem
          value={ContactOrderInviteSelectTabs.Registered}
          label="Зарегистрированные"
        />
      </Tabs>
      <Spacer size={8} />
      <ContactSelectTable
        selected={value}
        onChange={handleChange}
        data={data}
        total={total}
        loading={loading}
        onChangePayload={setPayload}
        validator={({ hasInviteByProduct, hasOrderByProduct, ...user }) =>
          validateTariff(
            {
              hasInviteByProduct,
              hasOrderByProduct,
              tariffType: tariffData?.tariff.type,
              userInfo: user,
            },
            {
              skipCheckInvite: !checkInvite,
              skipRegistered: !requiredRegistration,
            },
          )
        }
        validationData={validationData}
        pageSize={pageSize}
      />
    </div>
  );
};
