import React, { FC, useContext, useState } from 'react';

import { Container } from '@/shared';
import { PurchasesContext, PurchasesContextType } from '@entities/Purchases';
import {
  getOrdersStatus,
  OrdersTabs,
  useOrdersCounts,
} from '@entities/Purchases/libs/hooks';
import { PurchasesOrdersTable } from '@features/Purchases';
import { Divider, Spacer, TabItem, Tabs } from '@letsdance/ui-kit';

export const PurchasesLists: FC = () => {
  const { productUuid } = useContext<PurchasesContextType>(PurchasesContext);
  const [tab, setTab] = useState<OrdersTabs>(OrdersTabs.Active);
  const { activated, active, all, expired, refund, transferred } =
    useOrdersCounts({
      uuidProduct: productUuid,
    });

  return (
    <div>
      <Container>
        <Tabs initValue={tab} onChange={setTab}>
          <TabItem label={`Активные (${active})`} value={OrdersTabs.Active} />
          <TabItem
            label={`Возвращённые (${refund})`}
            value={OrdersTabs.Refund}
          />
          <TabItem
            label={`Активированные (${activated})`}
            value={OrdersTabs.Activated}
          />
          <TabItem label={`Истекшие (${expired})`} value={OrdersTabs.Expired} />
          <TabItem
            label={`Перенесённые (${transferred})`}
            value={OrdersTabs.Transferred}
          />
          <TabItem label={`Все (${all})`} value={OrdersTabs.All} />
        </Tabs>
      </Container>
      <Divider />
      <Spacer size={12} />
      <Container>
        <PurchasesOrdersTable
          uuidProduct={productUuid}
          status={getOrdersStatus(tab)}
        />
      </Container>
    </div>
  );
};
