import { useMediaQuery } from 'react-responsive';

import { openSupportTg } from '@/shared';
import { Button, Typography } from '@letsdance/ui-kit';
import macbookImg from '@shared/images/macbook.png';
import { ROLES } from '@widgets/Landing/const/text.conts';

import cls from './style.module.scss';

export const Questions = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 1260px)' });
  const openH2Dance = () => {
    window.open('https://h2.dance/', '_blank', 'noreferrer, noopener');
  };

  return (
    <section className={cls.section}>
      <header className={cls.header}>
        <Typography variant={isDesktop ? 'head-48' : 'head-32'}>
          Остались вопросы?
        </Typography>
        <Typography tag={'div'} variant={'body-20'} className={cls.desc}>
          Свяжитесь с нами и мы ответим на все возникшие вопросы.
        </Typography>
      </header>
      <img src={macbookImg} alt={'macbook'} className={cls.image} />
      <div className={cls.list}>
        {ROLES &&
          ROLES.map(([title, desc, linkText]) => (
            <div key={String(title) + desc + linkText} className={cls.item}>
              <div className={cls.text}>
                <Typography
                  tag="div"
                  variant={isDesktop ? 'head-32' : 'head-20'}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: title || '',
                    }}></span>{' '}
                  {linkText && (
                    <span onClick={openSupportTg} className={cls.link}>
                      {linkText}
                    </span>
                  )}
                </Typography>
                <Typography variant={'body-20'} className={cls.desc}>
                  {desc}
                </Typography>
              </div>
              <Button onClick={linkText ? openSupportTg : openH2Dance}>
                Попробовать
              </Button>
            </div>
          ))}
      </div>
    </section>
  );
};
