import { FC } from 'react';
import cx from 'classnames';

import { WithdrawalStatusEnum } from '@/apolloGenerated';
import { statusName } from '@entities/Withdraw';
import { Typography } from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export interface WithdrawStatusProps {
  status: WithdrawalStatusEnum;
}
export const WithdrawStatus: FC<WithdrawStatusProps> = ({ status }) => (
  <Typography
    variant="body-14"
    className={cx(styles.withdrawStatus, styles[`withdrawStatus_${status}`])}>
    {statusName[status]}
  </Typography>
);
