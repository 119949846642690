import { FC, PropsWithChildren } from 'react';

import styles from './styles.module.scss';

export const AuthLayout: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => (
  <div className={styles.auth}>
    <div className={styles.auth__container}>
      <div className={styles.auth__content}>{children}</div>
    </div>
  </div>
);
