import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { Maybe, UsersListQuery, UserSortKeys } from '@/apolloGenerated';
import { formatAmount, renderCellDate, renderCellItemValue } from '@/shared';
import { RouterName } from '@app/router';
import { ContactStatusIcon, ContactTabs } from '@entities/Contact';
import { useUsersList } from '@features/Contact/libs/hooks/useUsersList';
import {
  LoaderOverlay,
  SortType,
  TableController,
  Typography,
} from '@letsdance/ui-kit';

import { ContactControl } from '../ContactControl/ContactControl';

const headers = [
  { hide: true, key: true, value: 'uuid' },
  { value: 'special', width: 48 },
  { sortable: true, title: 'Дата регистрации', value: 'createdAt', width: 180 },
  { title: 'Фамилия/имя/ID', value: 'name' },
  { title: 'Данные', value: 'data' },
  { title: 'Покупки', value: 'orders' },
  { title: 'Посещено', value: 'visited' },
  { title: 'Потрачено, ₽', value: 'receipts' },
  { title: '', value: 'settings', width: 48 },
];
const pageSize = 15;
const DEFAULT_PAGE = 1;

export interface ContactTableProps {
  type?: ContactTabs;
  searchValue: string;
}

export const ContactTable: FC<ContactTableProps> = ({ searchValue, type }) => {
  const { urlFor } = useNavigate();
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<UserSortKeys>(UserSortKeys.CreatedAt);

  const { data, loading } = useUsersList({
    page,
    pageSize,
    search: searchValue,
    sort,
    sortBy,
    type,
  });

  useEffect(() => {
    setPage(DEFAULT_PAGE);
  }, [type]);

  const rowTemplate = (user: UsersListQuery['users']['rows'][0]) => ({
    createdAt: renderCellDate({ time: true, timestamp: user.createdAt }),
    data: renderCellItemValue({
      label: (
        <div className="flex">
          {user.username ? (
            <Typography
              variant="body-14"
              color="on-surface-primary-1"
              rel="noreferrer"
              style={{ marginRight: 4 }}>
              @{user.username}
            </Typography>
          ) : (
            '-'
          )}{' '}
          {user.phone && ' / ' + user.phone}
        </div>
      ),
      value: user.email,
    }),
    name: renderCellItemValue({
      label: `${user.last_name || ''} ${user.first_name}`,
      value: user.uuid,
    }),
    orders: String(user.ordersCount),
    receipts: formatAmount(user.receipts, true),
    settings: (
      <ContactControl
        uuid={user.uuid}
        username={user.username}
        name={`${user.last_name || ''} ${user.first_name}`}
        isSpecial={user.isSpecial}
        isBlocked={user.isBlocked}
        type={type}
      />
    ),
    special: (
      <ContactStatusIcon
        isSpecial={user.isSpecial}
        isBlocked={user.isBlocked}
      />
    ),
    uuid: user.uuid,
    visited: String(user.activatedOrdersCount),
  });
  const items = useMemo(
    () => (data?.rows || []).map((item) => rowTemplate(item)),
    [data],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={data?.count}
        pageSize={pageSize}
        onChangePage={setPage}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as UserSortKeys);
        }}
        onClickRow={(e, user) =>
          window.open(
            urlFor(RouterName.AdminContactEdit, { uuid: user.uuid }),
            '_blank',
          )
        }
        notResetPage
      />
    </div>
  );
};
