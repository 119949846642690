import React, { FC, useState } from 'react';

import {
  useUserGroupsQuery,
  useUsersBlockedCountQuery,
  useUsersSpecialCountQuery,
  useUsersStatsQuery,
} from '@/apolloGenerated';
import { useModal, useOrganizerId } from '@/shared';
import {
  Button,
  LockDenseIcon,
  PlusIcon,
  StarIcon,
  stopEvent,
  TabItem,
  Tabs,
  TrashDenseIcon,
} from '@letsdance/ui-kit';

export enum ContactTabsEnum {
  All = 'All',
  Active = 'Active',
  Special = 'Special',
  Blocked = 'Blocked',
}
export type ContactTabs = ContactTabsEnum & string;
export interface ContactListTabsProps {
  onChange(tab: ContactTabs): void;
}
export const ContactListTabs: FC<ContactListTabsProps> = ({ onChange }) => {
  const organizerId = useOrganizerId()!;
  const openCreateGroupModal = useModal('createUserGroup');
  const openRemoveGroupModal = useModal('removeUserGroup');
  const [tab, setTab] = useState<ContactTabs>(ContactTabsEnum.All);
  const { data: statsData } = useUsersStatsQuery({
    skip: !organizerId,
    variables: { organizerId },
  });
  const { data: activeStatsData } = useUsersStatsQuery({
    skip: !organizerId,
    variables: { isActive: true, organizerId },
  });
  const { data: specialCountData } = useUsersSpecialCountQuery({
    skip: !organizerId,
    variables: { organizerId },
  });
  const { data: blockedCountData } = useUsersBlockedCountQuery({
    skip: !organizerId,
    variables: { organizerId },
  });
  const { data: usersGroupData } = useUserGroupsQuery({
    skip: !organizerId,
    variables: { filters: {}, idOrganizer: organizerId },
  });
  const allCount = statsData?.usersStats.count || 0;
  const activeCount = activeStatsData?.usersStats.count || 0;
  const specialCount = specialCountData?.specialUsers.count || 0;
  const blockedCount = blockedCountData?.blacklist.count || 0;

  const handleChange = (tab: ContactTabs) => {
    onChange(tab);
    setTab(tab);
  };

  return (
    <div
      className="flex-align-center justify-space-between
    ">
      <Tabs initValue={tab} onChange={handleChange}>
        <TabItem value={ContactTabsEnum.All} label={`Все (${allCount})`} />
        <TabItem
          value={ContactTabsEnum.Active}
          label={`Активные пользователи (${activeCount})`}
        />
        <TabItem
          style={{ alignItems: 'center', display: 'flex', gap: 4 }}
          value={ContactTabsEnum.Special}>
          <StarIcon width={16} height={16} />
          Спецгости ({specialCount})
        </TabItem>
        <TabItem
          style={{ alignItems: 'center', display: 'flex', gap: 4 }}
          value={ContactTabsEnum.Blocked}>
          <LockDenseIcon width={16} height={16} />
          Заблокированные ({blockedCount})
        </TabItem>
        {(usersGroupData?.userGroups.rows || []).map((el) => (
          <TabItem
            key={el.uuid}
            style={{ alignItems: 'center', display: 'flex', gap: 8 }}
            value={el.uuid}>
            {el.name} ({el.usersCount})
            <TrashDenseIcon
              width={16}
              height={16}
              onClick={(event) => {
                stopEvent(event);
                openRemoveGroupModal({ name: el.name, uuid: el.uuid });
              }}
            />
          </TabItem>
        ))}
      </Tabs>
      <Button
        variant="text"
        prependIcon={PlusIcon}
        size="large"
        onClick={() => openCreateGroupModal()}>
        Добавить группу
      </Button>
    </div>
  );
};
