import React, { FC, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import {
  OrganizerInput,
  OrganizerType,
  useOrganizerQuery,
} from '@/apolloGenerated';
import {
  ActionButtons,
  ApolloErrorService,
  Container,
  useOrganizerId,
} from '@/shared';
import { RouterName } from '@app/router';
import {
  OrganizerForm,
  OrganizerInputSteps,
  useUpdateOrganizer,
} from '@entities/Organizer';
import { LoaderOverlay, Spacer } from '@letsdance/ui-kit';
import { ActionButton } from '@shared/ui/ActionButtons/ActionButtons';
import { Head } from '@widgets/Layout';

export const ProfileOrganizerView: FC = () => {
  const organizerId = useOrganizerId()!;
  const { navigate } = useNavigate();
  const [updateOrganizer, { error, loading: saveLoading }] =
    useUpdateOrganizer();
  const [organizerData, setOrganizerData] = useState<OrganizerInput>();

  const { data, loading, refetch } = useOrganizerQuery({
    skip: !organizerId,
    variables: {
      id: organizerId,
    },
  });
  const organizer = data?.organizer;

  const onClose = () => {
    navigate(RouterName.AdminEvents);
  };
  const onSave = async () => {
    await updateOrganizer({
      id: organizerId,
      input: organizerData!,
    });
    refetch();
  };

  const actions: ActionButton[] = [
    {
      handler: onClose,
      label: 'Отменить',
    },
    {
      handler: onSave,
      label: 'Сохранить изменения',
    },
  ];

  return (
    <>
      <LoaderOverlay show={loading || saveLoading} />
      <Container slim>
        <Head title="Профиль" />
        <OrganizerInputSteps data={organizer} />
      </Container>
      <Spacer size={8} />
      <ActionButtons actions={actions} />
      <Spacer size={20} />
      <Container slim>
        {data && (
          <OrganizerForm
            initValues={data.organizer as OrganizerType}
            onChange={setOrganizerData}
            errors={
              ApolloErrorService.getFirstError(error?.graphQLErrors)?.extensions
                ?.validationErrors
            }
          />
        )}
      </Container>
    </>
  );
};
