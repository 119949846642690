import React, { FC, useEffect } from 'react';

import { usePublishEventMutation } from '@/apolloGenerated';
import {
  Button,
  Dialog,
  DialogFooter,
  toaster,
  Typography,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface PublishEventModalProps {
  uuid: string;
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    publishEvent: PublishEventModalProps;
  }
}
export const PublishEventModal: FC<WithCloseModal<PublishEventModalProps>> = ({
  onClose,
  onSuccess,
  uuid,
}): JSX.Element => {
  const [publishEvent, { client, data, error, loading }] =
    usePublishEventMutation();

  const handleSubmit = () => {
    publishEvent({ variables: { uuid } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: 'Вечеринка успешно опубликована',
      });
      client.cache.evict({ fieldName: 'productsList' });
      client.cache.evict({ fieldName: 'productsCount' });
      client.cache.evict({ args: { uuid }, fieldName: 'product' });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка публикации. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Опубликовать вечеринку?"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button fullWidth onClick={handleSubmit} disabled={loading}>
                Подтвердить
              </Button>
            </div>
          }
        />
      }>
      <Typography variant="body-16" color="on-surface-primary-1">
        Опубликованная вечеринка будет доступна в разделе Активные. Перед
        активацией вечеринки проверьте правильность заполнения информации о ней.
      </Typography>
    </Dialog>
  );
};
