import { FC } from 'react';

import { IconProps } from '@letsdance/ui-kit';

export const ActiveIcon: FC<IconProps> = ({
  height = 24,
  width = 24,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22ZM16.4243 10.4243C16.6586 10.1899 16.6586 9.81005 16.4243 9.57574C16.1899 9.34142 15.8101 9.34142 15.5757 9.57574L11.5 13.6515L9.92426 12.0757C9.68995 11.8414 9.31005 11.8414 9.07574 12.0757C8.84142 12.3101 8.84142 12.6899 9.07574 12.9243L11.0757 14.9243C11.3101 15.1586 11.6899 15.1586 11.9243 14.9243L16.4243 10.4243Z"
      fill="#005BFE"
    />
  </svg>
);
