import { gql } from '@apollo/client';

export const jwtFragment = gql`
  fragment JwtFields on JwtType {
    access_token
    refresh_token
  }
`;
export const login = gql`
  ${jwtFragment}
  mutation login($login: String!, $password: String!) {
    login(input: { login: $login, password: $password }) {
      ...JwtFields
    }
  }
`;
export const refresh = gql`
  ${jwtFragment}
  mutation refresh($refresh_token: String!) {
    refresh(refresh_token: $refresh_token) {
      ...JwtFields
    }
  }
`;

export const me = gql`
  query me {
    me {
      uuid
      role
      username
      controlOrganizerId
    }
  }
`;
