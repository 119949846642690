import React, { FC, useEffect, useState } from 'react';

import { useAddGroupUsersMutation } from '@/apolloGenerated';
import { useOrganizerId } from '@/shared';
import {
  Button,
  Dialog,
  DialogFooter,
  TextField,
  toaster,
  WithCloseModal,
} from '@letsdance/ui-kit';

interface AddUserGroupModalProps {
  onSuccess?(): void;
}
declare global {
  interface ModalProps {
    createUserGroup: AddUserGroupModalProps;
  }
}
export const AddUserGroupModal: FC<WithCloseModal<AddUserGroupModalProps>> = ({
  onClose,
  onSuccess,
}): JSX.Element => {
  const idOrganizer = useOrganizerId()!;
  const [addGroupUsers, { client, data, error, loading }] =
    useAddGroupUsersMutation();
  const [name, setName] = useState<string>('');

  const handleSubmit = () => {
    addGroupUsers({ variables: { idOrganizer, name } });
  };

  useEffect(() => {
    if (data) {
      toaster.success({
        title: `Группа ${data.crateUserGroup.name} успешно создана`,
      });
      client.cache.evict({ fieldName: 'userGroups' });
      onSuccess && onSuccess();
    }
    if (error) {
      toaster.error({
        title: 'Ошибка создания группы. Обратитесь за помощью в техподдержку',
      });
    }
    if (data || error) {
      onClose();
    }
  }, [data, error]);

  return (
    <Dialog
      title="Создание пользовательской группы"
      closed
      width={500}
      footerSlot={
        <DialogFooter
          fullWidth
          endSlot={
            <div className="grid-2">
              <Button color="secondary" fullWidth onClick={onClose}>
                Отмена
              </Button>
              <Button
                fullWidth
                onClick={handleSubmit}
                disabled={loading || !name}>
                Создать
              </Button>
            </div>
          }
        />
      }>
      <TextField
        label="Название группы"
        size="large"
        fullWidth
        name="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </Dialog>
  );
};
