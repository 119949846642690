import React, { FC, useEffect, useMemo, useState } from 'react';

import { Maybe, UserSortKeys, UserType } from '@/apolloGenerated';
import { renderCellCheckbox, renderCellItemValue } from '@/shared';
import { ContactStatusIcon } from '@entities/Contact';
import { ValidationError } from '@entities/Tariff';
import {
  LoaderOverlay,
  SortType,
  TableController,
  Typography,
} from '@letsdance/ui-kit';

import {
  ContactListFilter,
  ContactListFilterData,
} from '../ContactListFilter/ContactListFilter';

const DEFAULT_PAGE = 1;

export type ContactSelectValidationHandler = (
  user: UserType,
) => ValidationError;
export interface ContactSelectTablePayload {
  page: number;
  sort: Maybe<SortType>;
  sortBy: UserSortKeys;
  filter: ContactListFilterData;
}
export interface ContactSelectTableProps {
  data: UserType[];
  selected: UserType[];
  validationData?: object;
  onChange(uuids: UserType[]): void;
  onChangePayload(payload: ContactSelectTablePayload): void;
  validator?: ContactSelectValidationHandler;
  total: number;
  loading: boolean;
  pageSize: number;
}

const headers = [
  { hide: true, key: true, value: 'uuid' },
  {
    value: 'check',
    width: 48,
  },
  { value: 'icon', width: 48 },
  { title: 'ФИО', value: 'name' },
  { title: 'Данные', value: 'data' },
];

export const ContactSelectTable: FC<ContactSelectTableProps> = ({
  data,
  loading,
  onChange,
  onChangePayload,
  pageSize,
  selected,
  total,
  validationData,
  validator,
}) => {
  const [filter, setFilter] = useState<ContactListFilterData>({ search: '' });
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [sort, setSort] = useState<Maybe<SortType>>('desc');
  const [sortBy, setSortBy] = useState<UserSortKeys>(UserSortKeys.CreatedAt);

  const filterUsers = (users: UserType[]): UserType[] =>
    users.filter((el) =>
      validator ? validator(el).status : true,
    ) as UserType[];

  useEffect(() => {
    onChange(filterUsers(selected));
  }, [data, validationData]);

  useEffect(() => {
    onChangePayload({ filter, page, sort, sortBy });
  }, [page, sort, sortBy, filter]);

  const handleChange = (checked: boolean, user: UserType) => {
    if (checked) {
      onChange([...selected, user]);
    } else {
      onChange(selected.filter((el) => el.uuid !== user.uuid));
    }
  };

  const rowTemplate = (user: UserType) => {
    const validation = validator
      ? validator(user)
      : { message: '', status: true };
    const checked = selected.some((el) => user.uuid === el.uuid);

    return {
      check: renderCellCheckbox({
        disabled: !validation.status,
        onChange(val: boolean) {
          handleChange(val, user as UserType);
        },
        tooltipText: validation.message,
        value: checked,
      }),
      data: renderCellItemValue({
        label: (
          <div className="flex">
            {user.username ? (
              <Typography
                variant="body-14"
                color="on-surface-primary-1"
                rel="noreferrer">
                @{user.username}
              </Typography>
            ) : (
              '-'
            )}{' '}
            {user.phone && ' / ' + user.phone}
          </div>
        ),
        value: user.email,
      }),
      icon: (
        <ContactStatusIcon
          isSpecial={user.isSpecial}
          isBlocked={user.isBlocked}
        />
      ),
      name: renderCellItemValue({
        label: `${user.last_name || ''} ${user.first_name}`,
        value: user.uuid,
      }),
      uuid: user.uuid,
    };
  };

  const items = useMemo(
    () => (data || []).map((item) => rowTemplate(item)),
    [data, selected],
  );

  return (
    <div className="relative">
      <ContactListFilter initValue={filter} onChange={setFilter} />
      <LoaderOverlay show={loading} />
      <TableController
        data={items}
        headers={headers}
        total={total}
        pageSize={pageSize}
        onChangePage={setPage}
        initSort={sort}
        initSortBy={sortBy!}
        onSort={(sort, sortBy) => {
          setSort(sort);
          setSortBy(sortBy as UserSortKeys);
        }}
        notResetPage
      />
    </div>
  );
};
