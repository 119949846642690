import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { useMeQuery } from '@/apolloGenerated';
import { logoutUser, useModal } from '@/shared';
import { history, router, RouterName } from '@app/router';
import {
  Avatar,
  Divider,
  Dropdown,
  DropdownItem,
  Paper,
  Typography,
} from '@letsdance/ui-kit';

import styles from './styles.module.scss';

export const ProfileControl: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openActionModal = useModal('action');
  const { data, loading } = useMeQuery();
  const user = data?.me;
  const { navigate } = useNavigate();

  const logoutCallback = useCallback(() => {
    setIsOpen(false);
    openActionModal({
      onSubmit() {
        logoutUser();
        history.navigate(router.urlFor(RouterName.Authorization));
      },
      submitBtnText: 'Выйти',
      title: 'Выйти из приложения?',
    });
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <Dropdown
      targetSlot={
        <Avatar
          name={user?.username || 'U'}
          className={styles.profileControl__account}
        />
      }
      value={isOpen}
      onChange={setIsOpen}
      placement="right-end"
      width={260}
      zIndex={1000}>
      <Paper px={4} py={4} radius={4}>
        <DropdownItem
          onClick={() => navigate(RouterName.AdminProfile)}
          fullWidth
          label="Профиль"
        />
        <Divider className={styles.profileControl__divider} />
        <DropdownItem onClick={logoutCallback} fullWidth>
          <Typography variant="body-16" color="error-2">
            Выйти
          </Typography>
        </DropdownItem>
      </Paper>
    </Dropdown>
  );
};
